// src/components/PreviousAddressForm.js
import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updatePreviousAddress } from '../../../redux/slice/personalBackgrountCheckSlice';
import { MdAdd } from "react-icons/md";
import { FaRegTrashCan } from "react-icons/fa6";

const PreviousAddressSchema = Yup.object().shape({
    previousAddress: Yup.array().of(
        Yup.object().shape({
            streetNumber: Yup.string().required('Field is required'),
            streetName: Yup.string().required('Field is required'),
            city: Yup.string().required('Field is required'),
            province: Yup.string().required('Field is required'),
            postalCode: Yup.string().required('Field is required'),
            country: Yup.string().required('Field is required'),
        })
    ),
});

const PreviousAddressForm = ({ nextStep, prevStep }) => {
    const dispatch = useDispatch();
    const previousAddress = useSelector((state) => state.backgroundCheck.previousAddress);

    const handleSubmit = (values) => {
        dispatch(updatePreviousAddress(values.previousAddress));
        nextStep();
    };

    const initialValues = {
        previousAddress: previousAddress.length ? previousAddress : [{
            streetNumber: '',
            streetName: '',
            city: '',
            province: '',
            postalCode: '',
            country: ''
        }],
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={PreviousAddressSchema}
                onSubmit={handleSubmit}
            >

                {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
                    <Form>
                        {/* <!-- Card Header --> */}
                        <div className="px-6 py-4 bg-gray-20 border-b border-gray-300">
                            <h2 className="text-xl font-semibold text-gray-800 mb-0">
                                Previous Address(es)
                            </h2>
                        </div>
                        {/* <!-- Card Body --> */}
                        <div className="px-6 py-4 bg-gray-20">
                            <FieldArray name="previousAddress">
                                {({ remove, push }) => (
                                    <>
                                        {values.previousAddress.map((address, index) => (
                                            <>
                                                <div key={index} className="previousAddress flex flex-wrap -mx-3">
                                                    {index != 0 && <div className='w-full md:w-1/1 px-3 mb-6'>
                                                        <h4 className="text-xl font-semibold text-gray-800 mb-0">Previous Address : {index + 1}</h4>
                                                    </div>}

                                                    <div className="w-full md:w-1/3 px-3 mb-5">
                                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor={`previousAddress.${index}.streetNumber`}>
                                                            Street Number
                                                        </label>
                                                        <input
                                                            id={`previousAddress.${index}.streetNumber`}
                                                            className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                            type="text"
                                                            name={`previousAddress.${index}.streetNumber`}
                                                            value={address.streetNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.previousAddress?.[index]?.streetNumber && touched.previousAddress?.[index]?.streetNumber && (
                                                            <p className="text-red-500 text-xs italic">{errors.previousAddress[index].streetNumber}</p>
                                                        )}
                                                    </div>
                                                    <div className="w-full md:w-1/3 px-3 mb-5">
                                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor={`previousAddress.${index}.streetName`}>
                                                            Street Name
                                                        </label>
                                                        <input
                                                            id={`previousAddress.${index}.streetName`}
                                                            className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                            type="text"
                                                            name={`previousAddress.${index}.streetName`}
                                                            value={address.streetName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.previousAddress?.[index]?.streetName && touched.previousAddress?.[index]?.streetName && (
                                                            <p className="text-red-500 text-xs italic">{errors.previousAddress[index].streetName}</p>
                                                        )}
                                                    </div>
                                                    <div className="w-full md:w-1/3 px-3 mb-5">
                                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor={`previousAddress.${index}.unitNumber`}>
                                                            Unit Number
                                                        </label>
                                                        <input
                                                            id={`previousAddress.${index}.unitNumber`}
                                                            className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                            type="text"
                                                            name={`previousAddress.${index}.unitNumber`}
                                                            value={address.unitNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {/* {errors.previousAddress?.[index]?.unitNumber && touched.previousAddress?.[index]?.unitNumber && (
                                                                <p className="text-red-500 text-xs italic">{errors.previousAddress[index].unitNumber}</p>
                                                            )} */}
                                                    </div>
                                                    <div className="w-full md:w-1/2 px-3 mb-5">
                                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor={`previousAddress.${index}.city`}>
                                                            City
                                                        </label>
                                                        <input
                                                            id={`previousAddress.${index}.city`}
                                                            className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                            type="text"
                                                            name={`previousAddress[${index}].city`}
                                                            value={address.city}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.previousAddress?.[index]?.city && touched.previousAddress?.[index]?.city && (
                                                            <p className="text-red-500 text-xs italic">{errors.previousAddress[index].city}</p>
                                                        )}
                                                    </div>
                                                    <div className="w-full md:w-1/2 px-3 mb-5">
                                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor={`previousAddress.${index}.province`}>
                                                            Province
                                                        </label>
                                                        <input
                                                            id={`previousAddress.${index}.province`}
                                                            className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                            type="text"
                                                            name={`previousAddress[${index}].province`}
                                                            value={address.province}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.previousAddress?.[index]?.province && touched.previousAddress?.[index]?.province && (
                                                            <p className="text-red-500 text-xs italic">{errors.previousAddress[index].province}</p>
                                                        )}
                                                    </div>
                                                    <div className="w-full md:w-1/2 px-3 mb-5">
                                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor={`previousAddress.${index}.postalCode`}>
                                                            Postal Code
                                                        </label>
                                                        <input
                                                            id={`previousAddress.${index}.postalCode`}
                                                            className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                            type="text"
                                                            name={`previousAddress[${index}].postalCode`}
                                                            value={address.postalCode}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.previousAddress?.[index]?.postalCode && touched.previousAddress?.[index]?.postalCode && (
                                                            <p className="text-red-500 text-xs italic">{errors.previousAddress[index].postalCode}</p>
                                                        )}
                                                    </div>
                                                    <div className="w-full md:w-1/2 px-3 mb-5">
                                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor={`previousAddress.${index}.country`}>
                                                            Country
                                                        </label>
                                                        <input
                                                            id={`previousAddress.${index}.country`}
                                                            className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                            type="text"
                                                            name={`previousAddress[${index}].country`}
                                                            value={address.country}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.previousAddress?.[index]?.country && touched.previousAddress?.[index]?.country && (
                                                            <p className="text-red-500 text-xs italic">{errors.previousAddress[index].country}</p>
                                                        )}
                                                    </div>
                                                    {index != 0 && <div className='w-full md:w-1/1 px-3'>
                                                        <button className="bg-red-500 text-white px-3 py-1 rounded-md shadow hover:bg-blue-600 float-right flex items-center justify-center" type="button" onClick={() => remove(index)}>
                                                            <FaRegTrashCan color='#fff' size={14} />
                                                            <span className='ml-1'>Remove</span>
                                                        </button>
                                                    </div>}
                                                </div>
                                            </>
                                        ))}
                                        <div className='d-inline float-left'>
                                            <button className="bg-blue-500 text-white px-3 py-1 rounded-md shadow hover:bg-blue-600 flex items-center justify-center" type="button" onClick={() => push({ streetNumber: '', streetName: '', city: '', province: '', postalCode: '', country: '' })}>
                                                <MdAdd color='#fff' size={18} />
                                                <span className='ml-1'>Add</span>
                                            </button>
                                        </div>
                                        <div className='clear-both'></div>
                                    </>
                                )}
                            </FieldArray>
                        </div>

                        {/* <!-- Card Footer --> */}
                        <div className="px-6 py-4 bg-gray-20 border-t border-gray-300 flex align-center justify-between">
                            <button type="submit" className="bgPrimaryColor uppercase text-white rounded-full px-8 py-3 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden">
                                Next
                            </button>
                            <button type="button" className="bg-gray-300 text-dark rounded-full px-8 py-3 shadow hover:bg-gray-300" onClick={prevStep}>Back</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PreviousAddressForm;
