import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateCriminalRecord } from '../../../redux/slice/personalBackgrountCheckSlice';
import icon from '../../../Images/double-arrows.png';
import { MdAdd } from 'react-icons/md';
import { FaRegTrashCan } from 'react-icons/fa6';


const CriminalRecordSchema = Yup.object().shape({
    criminalRecord: Yup.object().shape({
        lastName: Yup.string().required('Field is required'),
        givenName: Yup.string().required('Field is required'),
        dob: Yup.string().required('Field is required'),
        offenceDetails: Yup.array().of(
            Yup.object().shape({
                offence: Yup.string(),
                dos: Yup.string(),
                courtLocation: Yup.string(),
            })
        ),
    }),
});

function CriminalRecordForm({ nextStep, prevStep }) {
    const dispatch = useDispatch();
    const criminalRecord = useSelector((state) => state.backgroundCheck.criminalRecord);
    const personalInformation = useSelector((state) => state.backgroundCheck.personalInformation);

    const handleSubmit = (values) => {
        dispatch(updateCriminalRecord(values.criminalRecord));
        nextStep();
    };

    const initialValues = {
        criminalRecord: {
            lastName: personalInformation.lastName || '',
            givenName: personalInformation.givenName || '',
            dob: personalInformation.dob || '',
            offenceDetails: criminalRecord.offenceDetails.length ? criminalRecord.offenceDetails : [{
                offence: '',
                dos: '',
                courtLocation: ''
            }]
        },
    };


    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={CriminalRecordSchema}
                onSubmit={handleSubmit}
            >

                {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
                    <Form>
                        {/* <!-- Card Header --> */}
                        <div className="px-6 py-4 bg-gray-20 border-b border-gray-300">
                            <h2 className="text-xl font-semibold text-gray-800 mb-0">
                                Criminal Record
                            </h2>
                        </div>
                        {/* <!-- Card Body --> */}
                        <div className="px-6 py-4 bg-gray-20">
                            <div className="flex flex-wrap -mx-3">
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="lastName">
                                        Surname (Last Name)
                                    </label>
                                    <input
                                        id="lastName"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="criminalRecord.lastName"
                                        value={values.criminalRecord.lastName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        readOnly
                                    />
                                    {errors.criminalRecord?.lastName && touched.criminalRecord?.lastName && <p className="text-red-500 text-xs italic">{errors.criminalRecord?.lastName}</p>}
                                </div>
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="givenName">
                                        Given Name(s)
                                    </label>
                                    <input
                                        id="givenName"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="criminalRecord.givenName"
                                        value={values.criminalRecord.givenName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        readOnly
                                    />
                                    {errors.criminalRecord?.givenName && touched.criminalRecord?.givenName && <p className="text-red-500 text-xs italic">{errors.criminalRecord?.givenName}</p>}
                                </div>
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="dob">
                                        Date of Birth
                                    </label>
                                    <input
                                        id="dob"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="criminalRecord.dob"
                                        value={values.criminalRecord.dob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        readOnly
                                    />
                                    {errors.criminalRecord?.dob && touched.criminalRecord?.dob && <p className="text-red-500 text-xs italic">{errors.criminalRecord?.dob}</p>}
                                </div>
                                <div className="w-full md:w-1/1 px-3 mb-5">
                                    <p className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">
                                        A Declaration of Criminal Record does not constitute a Certified Criminal Record by the RCMP and may not contain all criminal record convictions.
                                    </p>
                                    <p className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">
                                        Applicants must declare all convictions for offences under Canadian federal law.
                                    </p>
                                    <p className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">
                                        Do not declare the following:
                                    </p>
                                    <ul className='custom-list'>
                                        <li className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">
                                            <img alt='' src={icon} />
                                            A conviction for which you have received a Record Suspension (formerly pardon) in accordance with the Criminal Records Act
                                        </li>
                                        <li className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">
                                            <img alt='' src={icon} />
                                            A conviction where you were a “young person” under the Youth Criminal Justice Act
                                        </li>
                                        <li className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">
                                            <img alt='' src={icon} />
                                            An Absolute or Conditional Discharge, pursuant to section 730 of the Criminal Code
                                        </li>
                                        <li className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">
                                            <img alt='' src={icon} />
                                            An offence for which you were not convicted
                                        </li>
                                        <li className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">
                                            <img alt='' src={icon} />
                                            Any provincial or municipal offence
                                        </li>
                                        <li className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">
                                            <img alt='' src={icon} />
                                            Any charged dealt with outside of Canada.
                                        </li>
                                    </ul>
                                    <h2 className="text-xl font-semibold text-gray-800 mb-0">
                                        Add Offence Details
                                    </h2>
                                </div>
                            </div>
                            {/* Offence Details Array */}
                            <FieldArray name="criminalRecord.offenceDetails">
                                {({ remove, push }) => (
                                    <div>
                                        {values.criminalRecord.offenceDetails.map((offence, index) => (
                                            <div key={index} className="flex flex-wrap -mx-3">
                                                {index !== 0 && (
                                                    <div className="w-full md:w-1/1 px-3 mb-6">
                                                        <h4 className="text-xl font-semibold text-gray-800 mb-0">Offence : {index + 1}</h4>
                                                    </div>
                                                )}
                                                <div className="w-full md:w-1/3 px-3 mb-5">
                                                    <label
                                                        className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2"
                                                        htmlFor={`criminalRecord.offenceDetails.${index}.offence`}
                                                    >
                                                        Offence
                                                    </label>
                                                    <input
                                                        id={`criminalRecord.offenceDetails.${index}.offence`}
                                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                        type="text"
                                                        name={`criminalRecord.offenceDetails.${index}.offence`}
                                                        value={offence.offence}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.criminalRecord?.offenceDetails?.[index]?.offence &&
                                                        touched.criminalRecord?.offenceDetails?.[index]?.offence && (
                                                            <p className="text-red-500 text-xs italic">
                                                                {errors.criminalRecord?.offenceDetails[index].offence}
                                                            </p>
                                                        )}
                                                </div>
                                                <div className="w-full md:w-1/3 px-3 mb-5">
                                                    <label
                                                        className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2"
                                                        htmlFor={`criminalRecord.offenceDetails.${index}.dos`}
                                                    >
                                                        Date of Offence
                                                    </label>
                                                    <input
                                                        id={`criminalRecord.offenceDetails.${index}.dos`}
                                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                        type="text"
                                                        name={`criminalRecord.offenceDetails.${index}.dos`}
                                                        value={offence.dos}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.criminalRecord?.offenceDetails?.[index]?.dos &&
                                                        touched.criminalRecord?.offenceDetails?.[index]?.dos && (
                                                            <p className="text-red-500 text-xs italic">
                                                                {errors.criminalRecord?.offenceDetails[index].dos}
                                                            </p>
                                                        )}
                                                </div>
                                                <div className="w-full md:w-1/3 px-3 mb-5">
                                                    <label
                                                        className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2"
                                                        htmlFor={`criminalRecord.offenceDetails.${index}.courtLocation`}
                                                    >
                                                        Court Location
                                                    </label>
                                                    <input
                                                        id={`criminalRecord.offenceDetails.${index}.courtLocation`}
                                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                        type="text"
                                                        name={`criminalRecord.offenceDetails.${index}.courtLocation`}
                                                        value={offence.courtLocation}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.criminalRecord?.offenceDetails?.[index]?.courtLocation &&
                                                        touched.criminalRecord?.offenceDetails?.[index]?.courtLocation && (
                                                            <p className="text-red-500 text-xs italic">
                                                                {errors.criminalRecord?.offenceDetails[index].courtLocation}
                                                            </p>
                                                        )}
                                                </div>

                                                {index !== 0 && (
                                                    <div className="w-full md:w-1/1 px-3">
                                                        <button
                                                            className="bg-red-500 text-white px-3 py-1 rounded-md shadow hover:bg-red-500 float-right flex items-center justify-center"
                                                            type="button"
                                                            onClick={() => remove(index)}
                                                        >
                                                            <FaRegTrashCan color='#fff' size={14} />
                                                            <span className='ml-1'>Remove</span>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        <div className="d-inline float-left">
                                            <button
                                                className="bg-blue-500 text-white px-3 py-1 rounded-md shadow hover:bg-blue-600 flex items-center justify-center"
                                                type="button"
                                                onClick={() => push({ offence: '', dos: '', courtLocation: '' })}
                                            >
                                                <MdAdd color='#fff' size={18} />
                                                <span className='ml-1'>Add</span>
                                            </button>
                                        </div>
                                        <div className="clear-both"></div>
                                    </div>
                                )}
                            </FieldArray>
                        </div>

                        {/* <!-- Card Footer --> */}
                        <div className="px-6 py-4 bg-gray-20 border-t border-gray-300 flex align-center justify-between">
                            <button type="submit" className="bgPrimaryColor uppercase text-white rounded-full px-8 py-3 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden">
                                Next
                            </button>
                            <button type="button" className="bg-gray-300 text-dark rounded-full px-8 py-3 shadow hover:bg-gray-300" onClick={prevStep}>Back</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default CriminalRecordForm;