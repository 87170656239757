// src/components/ReasonForRequestForm.js
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateReasonForCRV } from '../../../redux/slice/personalBackgrountCheckSlice';

const ReasonForRequestSchema = Yup.object().shape({
    reasonForCRV: Yup.object().shape({
        reasonForRequest: Yup.string().required('Field is required'),
        organizationRequestingSearch: Yup.string().required('Field is required'),
        contactName: Yup.string().required('Field is required'),
        contactPhone: Yup.string().required('Field is required'),
    }),
});

const ReasonForRequestForm = ({ nextStep, prevStep }) => {
    const dispatch = useDispatch();
    const reasonForCRV = useSelector((state) => state.backgroundCheck.reasonForCRV);

    const handleSubmit = (values) => {
        dispatch(updateReasonForCRV(values.reasonForCRV));
        nextStep();
    };

    return (
        <>
            <Formik
                initialValues={{
                    reasonForCRV: {
                        reasonForRequest: reasonForCRV.reasonForRequest || '',
                        organizationRequestingSearch: reasonForCRV.organizationRequestingSearch || '',
                        contactName: reasonForCRV.contactName || '',
                        contactPhone: reasonForCRV.contactPhone || '',
                    },
                }}
                validationSchema={ReasonForRequestSchema}
                onSubmit={handleSubmit}
            >

                {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
                    <Form>
                        {/* <!-- Card Header --> */}
                        <div className="px-6 py-4 bg-gray-20 border-b border-gray-300">
                            <h2 className="text-xl font-semibold text-gray-800 mb-0">
                                Reason for the Criminal Record Verification
                            </h2>
                        </div>
                        {/* <!-- Card Body --> */}
                        <div className="px-6 py-4 bg-gray-20">
                            <div className="flex flex-wrap -mx-3">
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="streetNumber">
                                        Reason for Request (example Employment-Employer-Job Title)
                                    </label>
                                    <input
                                        id="streetNumber"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="reasonForCRV.reasonForRequest"
                                        value={values.reasonForCRV.reasonForRequest}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.reasonForCRV?.reasonForRequest && touched.reasonForCRV?.reasonForRequest && <p className="text-red-500 text-xs italic">{errors.reasonForCRV?.reasonForRequest}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="streetNumber">
                                        Organization Requesting Search
                                    </label>
                                    <input
                                        id="streetNumber"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="reasonForCRV.organizationRequestingSearch"
                                        value={values.reasonForCRV.organizationRequestingSearch}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.reasonForCRV?.organizationRequestingSearch && touched.reasonForCRV?.organizationRequestingSearch && <p className="text-red-500 text-xs italic">{errors.reasonForCRV?.organizationRequestingSearch}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="streetNumber">
                                        Contact Name
                                    </label>
                                    <input
                                        id="streetNumber"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="reasonForCRV.contactName"
                                        value={values.reasonForCRV.contactName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.reasonForCRV?.contactName && touched.reasonForCRV?.contactName && <p className="text-red-500 text-xs italic">{errors.reasonForCRV?.contactName}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="streetNumber">
                                        Contact Phone Number
                                    </label>
                                    <input
                                        id="streetNumber"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="reasonForCRV.contactPhone"
                                        value={values.reasonForCRV.contactPhone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.reasonForCRV?.contactPhone && touched.reasonForCRV?.contactPhone && <p className="text-red-500 text-xs italic">{errors.reasonForCRV?.contactPhone}</p>}
                                </div>
                            </div>
                        </div>

                        {/* <!-- Card Footer --> */}
                        <div className="px-6 py-4 bg-gray-20 border-t border-gray-300 flex align-center justify-between">
                            <button type="submit" className="bgPrimaryColor uppercase text-white rounded-full px-8 py-3 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden">
                                Next
                            </button>
                            <button type="button" className="bg-gray-300 text-dark rounded-full px-8 py-3 shadow hover:bg-gray-300" onClick={prevStep}>Back</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ReasonForRequestForm;
