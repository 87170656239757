// backgroundcheckSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    personalInformation: {
        lastName: '',
        givenName: '',
        lastNameAtBirth: '',
        formerName: '',
        email: '',
        phoneNumber: '',
        birthPlace: '',
        dob: '',
        gender: ''
    },
    currentAddress: {
        streetNumber: '',
        streetName: '',
        unitNumber: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
        past5years:false
    },
    previousAddress: [
        {
            streetNumber: '',
            streetName: '',
            unitNumber: '',
            city: '',
            province: '',
            postalCode: '',
            country: ''
        }
    ],
    reasonForCRV: {
        reasonForRequest: '',
        organizationRequestingSearch: '',
        contactName: '',
        contactPhone: ''
    },
    criminalRecord: {
        lastName: '',
        givenName: '',
        dob: '',
        offenceDetails: [{
            offence: '',
            dos: '',
            courtLocation: ''
        }]
    },
    informedConsent: {
        searchAuthorization: false,
        policeInformationSystems: false,
        policeInfoSystems: {
            CPICData: false,
            PIO: false,
            other: false,
        },
        AuthorizationWaiver: false,
        metaforceConsentSystems: false,
        ThirdPartyOrg: '',
        SignedAt: '',
        dateSigned: '',
        applicantSignature: ''
    },
    idVerification: {
        idOne: '',
        idTwo: '',
        additionalId: ''
    },
    personal: {
        greet: 'Greeting from Personal',
    },
    business: {
        greet: 'Greeting from Business',
    },
};

export const backgroundCheckSlice = createSlice({
    name: 'backgroundCheck',
    initialState,
    reducers: {
        updatePersonalInformation(state, action) {
            state.personalInformation = action.payload;
        },
        updateCurrentAddress(state, action) {
            state.currentAddress = action.payload;
        },
        updatePreviousAddress(state, action) {
            state.previousAddress = action.payload;
        },
        updateReasonForCRV(state, action) {
            state.reasonForCRV = action.payload;
        },
        updateCriminalRecord(state, action) {
            state.criminalRecord = action.payload;
        },
        updateInformedConsent(state, action) {
            state.informedConsent = action.payload;
        },
        updateIdVerification(state, action) {
            state.idVerification = action.payload;
        },

        setPersonalCheck: (state, action) => {
            state.personal = action.payload;
        },
        setBusinessCheck: (state, action) => {
            state.business = action.payload;
        },
    },
});

// Export the correct actions
export const {
    setPersonalCheck,
    setBusinessCheck,
    updatePersonalInformation,
    updateCurrentAddress,
    updatePreviousAddress,
    updateReasonForCRV,
    updateCriminalRecord,
    updateInformedConsent,
    updateIdVerification } = backgroundCheckSlice.actions;
export default backgroundCheckSlice.reducer;
