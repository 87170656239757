import './App.css';
import 'antd/dist/antd.css';
import Header from './Components/Header';

function App(props) {
  return (
    <>
      <div className="App overflow-x-hidden">
        <Header />
      </div>
    </>
  );
}

export default App;
