import React from 'react';
import { personalCheckGoogleReviews } from '../../../utils/commonJSON';

const GoogleReviewSection = () => {
  return (
    <div className="review-section padding-mob pb-10">
      <h4 className="text-3xl text-gray-800 font-bold mb-3 pt-5 pb-7 section-title section-heading"><span className="border-b-2 border-blue-600">Our Google Reviews</span></h4>
      <div className="review-cards-container">
        {personalCheckGoogleReviews.map((review, index) => (
          <div key={index} className="review-card">
            <div className="review-header">
              <img src={review.image} alt={`${review.name}'s profile`} className="review-image" />
              <div>
                <h3 className="review-name">{review.name}</h3>
                <div className="review-rating">
                  {Array.from({ length: 5 }, (_, starIndex) => (
                    <span key={starIndex} className={starIndex < review.rating ? "star filled" : "star"}>
                      ★
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <p className="review-text">"{review.reviewText}"</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoogleReviewSection;
