// src/components/CurrentAddressForm.js
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentAddress } from '../../../redux/slice/personalBackgrountCheckSlice';

const CurrentAddressSchema = Yup.object().shape({
    currentAddress: Yup.object().shape({
        streetNumber: Yup.string().required('Field is required'),
        streetName: Yup.string().required('Field is required'),
        city: Yup.string().required('Field is required'),
        province: Yup.string().required('Field is required'),
        postalCode: Yup.string().required('Field is required'),
        country: Yup.string().required('Field is required'),
    }),
});

const CurrentAddressForm = ({ nextStep, prevStep }) => {
    const dispatch = useDispatch();
    const currentAddress = useSelector((state) => state.backgroundCheck.currentAddress);

    const handleSubmit = (values) => {
        dispatch(updateCurrentAddress(values.currentAddress));
        nextStep();
    };

    return (
        <>
            <Formik
                initialValues={{
                    currentAddress: {
                        streetNumber: currentAddress.streetNumber || '',
                        streetName: currentAddress.streetName || '',
                        unitNumber: currentAddress.unitNumber || '',
                        city: currentAddress.city || '',
                        province: currentAddress.province || '',
                        postalCode: currentAddress.postalCode || '',
                        country: currentAddress.country || '',
                        past5years: currentAddress.past5years || false,
                    },
                }}
                validationSchema={CurrentAddressSchema}
                onSubmit={handleSubmit}
            >

                {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
                    <Form>
                        {/* <!-- Card Header --> */}
                        <div className="px-6 py-4 bg-gray-20 border-b border-gray-300">
                            <h2 className="text-xl font-semibold text-gray-800 mb-0">
                                Current Address
                            </h2>
                        </div>
                        {/* <!-- Card Body --> */}
                        <div className="px-6 py-4 bg-gray-20">
                            <div className="flex flex-wrap -mx-3">
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="streetNumber">
                                        Street Number
                                    </label>
                                    <input
                                        id="streetNumber"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="currentAddress.streetNumber"
                                        value={values.currentAddress.streetNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.currentAddress?.streetNumber && touched.currentAddress?.streetNumber && <p className="text-red-500 text-xs italic">{errors.currentAddress?.streetNumber}</p>}
                                </div>
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="streetName">
                                        Street Name
                                    </label>
                                    <input
                                        id="streetName"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="currentAddress.streetName"
                                        value={values.currentAddress.streetName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.currentAddress?.streetName && touched.currentAddress?.streetName && <p className="text-red-500 text-xs italic">{errors.currentAddress?.streetName}</p>}
                                </div>
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="unitNumber">
                                        Unit Number
                                    </label>
                                    <input
                                        id="unitNumber"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="currentAddress.unitNumber"
                                        value={values.currentAddress.unitNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {/* {errors.streetName && touched.streetName && <p className="text-red-500 text-xs italic">{errors.streetName}</p>} */}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="city">
                                        City
                                    </label>
                                    <input
                                        id="city"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="currentAddress.city"
                                        value={values.currentAddress.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.currentAddress?.city && touched.currentAddress?.city && <p className="text-red-500 text-xs italic">{errors.currentAddress?.city}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="province">
                                        Province
                                    </label>
                                    <input
                                        id="citprovincey"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="currentAddress.province"
                                        value={values.province}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.currentAddress?.province && touched.currentAddress?.province && <p className="text-red-500 text-xs italic">{errors.currentAddress?.province}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="postalCode">
                                        Postal Code
                                    </label>
                                    <input
                                        id="postalCode"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="currentAddress.postalCode"
                                        value={values.currentAddress.postalCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.currentAddress?.postalCode && touched.currentAddress?.postalCode && <p className="text-red-500 text-xs italic">{errors.currentAddress?.postalCode}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="country">
                                        Country
                                    </label>
                                    <input
                                        id="country"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="currentAddress.country"
                                        value={values.currentAddress.country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.currentAddress?.country && touched.currentAddress?.country && <p className="text-red-500 text-xs italic">{errors.currentAddress?.country}</p>}
                                </div>
                                <div className="w-full md:w-1/1 px-3 mb-5">
                                    <label className="container-checkbox block tracking-wide text-gray-700">
                                        I confirm that I have continuously lived at my current address for the past 5 years.
                                        <input
                                            type="checkbox"
                                            name="currentAddress.past5years"
                                            checked={values.currentAddress.past5years}
                                            onChange={handleChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Card Footer --> */}
                        <div className="px-6 py-4 bg-gray-20 border-t border-gray-300 flex align-center justify-between">
                            <button type="submit" className="bgPrimaryColor uppercase text-white rounded-full px-8 py-3 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden">
                                Next
                            </button>
                            <button type="button" className="bg-gray-300 text-dark rounded-full px-8 py-3 shadow hover:bg-gray-300" onClick={prevStep}>Back</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CurrentAddressForm;
