import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import personalCheckImg from '../../../OIFendImages/images/backgroundCheck/svg/personal-check.svg';
import './index.css';
import TestimonialSlider from "./testimonials";
import GoogleReviewSection from "./googleReviewSection";
import { LuShieldCheck } from "react-icons/lu";
import CriminalCheckImg from '../../../Images/criminal-backgroundchecks.webp';
import { FiCheckCircle } from "react-icons/fi";
import { MdOutlineSecurity } from "react-icons/md";
import { BsRocketTakeoff } from "react-icons/bs";
import { FaRegCircleCheck } from "react-icons/fa6";
import FAQs from "./faq";
import { useState } from "react";
// import PdfGenerator from "./pdfGenerator";

export const PersonalCheck = () => {
    return (
        <>
            <div className="container padding-mob mx-auto  flex items-center flex-wrap mt-12">
                <div className="w-full md:w-1/2 pr-0 lg:pr-10 text-left mob-order-2">
                    <h1 className="mb-4 text-3xl hero-heading font-extrabold leading-none tracking-tight md:text-4xl lg:text-5xl">
                        {/* Ensuring safety through transparency */}
                        Discover the Truth Protect Yourself
                    </h1>
                    <h2 className="text-xl font-medium hero-sub-heading">
                        Get the information you need to make informed decisions quickly and confidentially. Trust our accurate, thorough background checks for individuals and businesses.
                    </h2>
                    <div className="py-8 mob-text-center">
                        <span className="mt-8 md:mt-4 bgPrimaryColor uppercase rounded-full px-5 py-3 mb-16 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden">
                            <Link to="/background-check/form" className="text-white hover:text-white">
                                Request Background Check
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="w-full md:w-1/2 mb-8 lg:mb-0">
                    <img className="rounded-lg" src={personalCheckImg} alt="Vortex" />
                </div>
            </div>
            <div className="container padding-mob mx-auto flex items-center flex-wrap mt-10 mb-5">
                <div className="w-full md:w-1/1">
                    <h4 className="text-3xl text-gray-800 font-bold mb-3 pt-10 pb-5 section-heading">
                        <span className="border-b-2 border-blue-600 uppercase">Why It Is Important</span>
                    </h4>
                    <h2 className="text-xl font-medium">
                        In today’s world, ensuring safety and trust is paramount. Conducting a personal criminal background check can provide
                        essential peace of mind, whether you're verifying someone’s history for personal, professional, or rental purposes.
                        Our service is designed to be fast, accurate, and private, offering you easy access to reliable criminal records
                        and background information.
                    </h2>
                </div>
            </div>
            <div className="container padding-mob mx-auto flex flex-wrap pt-12 section-3">
                <div className="w-full lg:w-1/2  mx-auto text-left">
                    <img src={CriminalCheckImg} className="rounded-2xl shadow-xl mt-1" alt="CriminalCheckImg" />
                </div>
                <div className="w-full lg:w-1/2 mx-auto text-left md:pl-8 second-col">
                    <h4 className="text-2xl text-gray-800 font-bold mb-3">
                        When to Use a Criminal Background Check
                    </h4>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Employment Purpose
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Employers conduct checks to ensure potential hires meet safety and compliance standards, especially for roles involving sensitive responsibilities.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Security License
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Background checks are required to verify eligibility for security licenses, ensuring professionalism and trustworthiness in the industry.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Uber/Rideshare
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Rideshare companies like Uber use criminal checks to ensure drivers meet safety standards, protecting passengers.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Personal Use
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Whether for a roommate, business partner, or acquaintance, background checks provide peace of mind and added security.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Nanny/Caregiver
                            </h4>
                            <p className="text-gray-800 mb-0">
                                When hiring a nanny or caregiver, a criminal check ensures you are entrusting loved ones to a reliable and trustworthy individual.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container padding-mob mx-auto flex items-center flex-wrap mt-10 mb-5">
                <div className="w-full md:w-1/1 mb-5">
                    <h4 className="text-3xl text-gray-800 font-bold mb-0 pt-10 pb-5 section-heading">
                        <span className="border-b-2 border-blue-600 uppercase">How It Works</span>
                    </h4>
                    <h2 className="text-lg font-medium">
                        We can process applications in 15 minutes
                    </h2>
                </div>
                <div className="w-full lg:w-1/4 md:1/2">
                    <div>
                        <p className="text-5xl m-0 mb-2 text-center flex items-center justify-center">
                            <FaRegCircleCheck color="#24357C" className="mt-1" />
                        </p>
                        <h4 className="text-lg text-gray-800 font-bold mb-1 text-center">
                            Enter Basic Information
                        </h4>
                        <p className="text-gray-800 mb-0 text-center">
                            Provide the person’s name, date of birth, and any other required identifying details.
                        </p>
                    </div>
                </div>
                <div className="w-full lg:w-1/4 md:1/2">
                    <div>
                        <p className="text-5xl m-0 mb-2 text-center flex items-center justify-center">
                            <FaRegCircleCheck color="#24357C" className="mt-1" />
                        </p>
                        <h4 className="text-lg text-gray-800 font-bold mb-1 text-center">
                            Advanced Data Search
                        </h4>
                        <p className="text-gray-800 mb-0 text-center">
                            Our system search in RCMP Databases for records to deliver accurate and reliable results.
                        </p>
                    </div>
                </div>
                <div className="w-full lg:w-1/4 md:1/2">
                    <div>
                        <p className="text-5xl m-0 mb-2 text-center flex items-center justify-center">
                            <FaRegCircleCheck color="#24357C" className="mt-1" />
                        </p>
                        <h4 className="text-lg text-gray-800 font-bold mb-1 text-center">
                            Review and Verify
                        </h4>
                        <p className="text-gray-800 mb-0 text-center">
                            Receive a detailed report, including all relevant criminal records, and verify the information for your purpose.
                        </p>
                    </div>
                </div>
                <div className="w-full lg:w-1/4 md:1/2">
                    <div>
                        <p className="text-5xl m-0 mb-2 text-center flex items-center justify-center">
                            <FaRegCircleCheck color="#24357C" className="mt-1" />
                        </p>
                        <h4 className="text-lg text-gray-800 font-bold mb-1 text-center">
                            Save or Share Results
                        </h4>
                        <p className="text-gray-800 mb-0 text-center">
                            Download the report for personal use or share it securely with others when required.
                        </p>
                    </div>
                </div>
            </div>
            <GoogleReviewSection />
            <div className="container padding-mob mx-auto flex items-center flex-wrap mt-10 mb-10">
                <div className="w-full md:w-1/1 mb-5">
                    <h4 className="text-3xl text-gray-800 font-bold pt-5 section-heading">
                        <span className="border-b-2 border-blue-600 uppercase">FAQ</span>
                    </h4>
                    <FAQs />
                </div>
            </div>
            <TestimonialSlider />
            <div className="container padding-mob mx-auto flex flex-wrap">

                <div className="w-full md:w-1/3  mx-auto text-left">
                    <div className="flex justify-start mb-0 p-5">
                        <span className="text-5xl">
                            <FiCheckCircle color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Simple Process
                            </h4>
                            <p className="text-gray-800 mb-0">
                                With our easy-to-use system, you can complete a criminal background check online in minutes.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-1/3  mx-auto text-left">
                    <div className="flex justify-start mb-0 p-5">
                        <span className="text-5xl">
                            <BsRocketTakeoff color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Fast & Confidential
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Receive your report quickly and securely with 100% confidentiality. Your data privacy is our priority.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-1/3  mx-auto text-left">
                    <div className="flex justify-start mb-0 p-5">
                        <span className="text-5xl">
                            <MdOutlineSecurity color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Trustworthy Results
                            </h4>
                            <p className="text-gray-800 mb-0">
                                When someone is coming into your home, you need to be confident in their trustworthiness.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            {/* <PdfGenerator /> */}
        </>
    )
}
