import React, { useState } from 'react';
import Loader from './loder';
import StepFormContainer from './stepFormContainer';
const PersonalBackgroundCheckForm = () => {
    return (
        <>
            <div className="container padding-mob mx-auto flex items-center flex-wrap mt-8 mb-12">
                <div className="w-full md:w-1/1 pr-0 mx-auto text-left">
                    <h4 className="text-2xl text-gray-800 font-bold mb-3 pt-5 pb-5 text-center">
                        Submit Your Details for a Criminal Background Check
                    </h4>
                    <div className="bg-white shadow-lg overflow-hidden border border-gray-150">
                        <Loader />
                        <StepFormContainer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PersonalBackgroundCheckForm;
