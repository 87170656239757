import { Link } from "react-router-dom";
import businessCheckImg from '../../../OIFendImages/images/backgroundCheck/svg/business-check.svg';
import { LuShieldCheck } from "react-icons/lu";
import CriminalCheckImg from '../../../Images/business-background-check.webp';
import BGImg from '../../../Images/bz-check-1.webp';
import { RiCheckDoubleFill } from "react-icons/ri";
import './index.css';
import { FaRegCircleCheck } from "react-icons/fa6";
import { PiWarningFill } from "react-icons/pi";
import { FaClipboardCheck } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { HiClipboardDocumentList } from "react-icons/hi2";
import FAQs from "./faq";
import { Col, Row } from "antd";

export const BussinessCheck = () => {
    return (
        <>
            <div className="container padding-mob mx-auto  flex items-center flex-wrap mt-12">
                <div className="w-full md:w-1/2 pr-0 lg:pr-10 text-left mob-order-2">
                    <h1 className="mb-4 text-3xl font-extrabold hero-heading leading-none tracking-tight md:text-4xl lg:text-5xl">
                        Verified Trust Builds Strong Businesses
                    </h1>
                    <h2 className="text-xl font-medium hero-sub-heading">
                        Make smarter decisions by knowing the facts. Trust is earned, not assumed. Verify every partnership, hire, and investment for a future built on confidence.
                    </h2>
                    <div className="py-8 mob-text-center">
                        <span className="mt-8 md:mt-4 bgPrimaryColor uppercase rounded-full px-5 py-3 mb-16 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden">
                            <Link to="/background-check/form" className="text-white hover:text-white">
                                Request Background Check
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="w-full md:w-1/2 mb-8 lg:mb-0">
                    <img className="rounded-lg" src={businessCheckImg} alt="Vortex" />
                </div>
            </div>
            <div className="container padding-mob mx-auto flex items-center flex-wrap mt-10 mb-5">
                <div className="w-full md:w-1/1">
                    <h4 className="text-3xl text-gray-800 font-bold mb-3 pt-10 pb-5 section-heading">
                        <span className="border-b-2 border-blue-600 uppercase">Protect Your Business with Smarter Hiring Decisions</span>
                    </h4>
                    <h2 className="text-xl font-medium">
                        Hiring or partnering with the wrong person can have long-term consequences for your business. A business background
                        check ensures that you have all the essential information about your employees, job applicants, or potential partners.
                        From verifying criminal history to checking employment records, we help you make informed decisions that safeguard your
                        business's integrity and reputation.
                    </h2>
                </div>
            </div>
            <div className="container padding-mob mx-auto flex flex-wrap pt-12 mb-10 section-3">
                <div className="w-full lg:w-1/2  mx-auto text-left">
                    <img src={CriminalCheckImg} className="rounded-2xl shadow-xl mt-1" alt="CriminalCheckImg" />
                </div>
                <div className="w-full lg:w-1/2 mx-auto text-left md:pl-8 second-col">
                    <h4 className="text-2xl text-gray-800 font-bold mb-3">
                        When to Use a Business Background Check?
                    </h4>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Pre-Employment Screening
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Verify an applicant's criminal history, education, and employment records to ensure they meet your business standards.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Screening Contractors and Freelancers
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Ensure third-party contractors or freelancers have a clean and reliable history before assigning critical business tasks.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Promoting Internal Candidates
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Conduct checks on internal employees being considered for higher responsibilities to ensure their suitability.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Partnership Evaluations
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Verify the background of potential business partners to ensure trust and transparency.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-start mb-0">
                        <span className="text-2xl">
                            <LuShieldCheck color="#24357C" className="mt-1" />
                        </span>
                        <div className="ml-3">
                            <h4 className="text-lg text-gray-800 font-bold mb-1">
                                Sensitive Roles or Access
                            </h4>
                            <p className="text-gray-800 mb-0">
                                Perform checks for employees in sensitive positions, such as financial or IT roles, to mitigate risks.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="bg-gray-100 py-12 mb-10">
                <div className="container mx-auto pb-7 padding-mob">

                    <div className="w-full md:w-1/1 mb-10">
                        <h4 className="text-3xl text-gray-800 font-bold mb-3 pb-4 section-heading">
                            <span className="border-b-2 border-blue-600 uppercase">Our Business Background Checks Offer</span>
                        </h4>
                        <h2 className="text-lg font-medium">
                            Here are the common types of background checks that businesses use to ensure they hire trustworthy employees or partner with reliable individuals:
                        </h2>
                    </div>
                    <Row gutter={[20, 35]} style={{ display: "flex", alignItems: "stretch" }}>
                        <Col md={12} lg={6} style={{ display: "flex" }}>
                            <div className="bg-white shadow-lg p-5 rounded-lg custom-service-border flex-1">
                                <h4 className="text-lg text-gray-800 font-bold mb-1">
                                    Criminal Background
                                </h4>
                                <p className="text-gray-800 mb-0">
                                    Verify if the candidate or individual has any criminal records, including felonies, misdemeanors, or pending legal cases, to ensure workplace safety.
                                </p>
                            </div>
                        </Col>
                        <Col md={12} lg={6} style={{ display: "flex" }}>
                            <div className="bg-white shadow-lg p-5 rounded-lg custom-service-border flex-1">
                                <h4 className="text-lg text-gray-800 font-bold mb-1">
                                    Employment Verification
                                </h4>
                                <p className="text-gray-800 mb-0">
                                    Confirm the candidate’s previous work experience, including job titles, responsibilities, and tenure, to validate their resume claims.
                                </p>
                            </div>
                        </Col>
                        <Col md={12} lg={6} style={{ display: "flex" }}>
                            <div className="bg-white shadow-lg p-5 rounded-lg custom-service-border flex-1">
                                <h4 className="text-lg text-gray-800 font-bold mb-1">
                                    Credit History Checks
                                </h4>
                                <p className="text-gray-800 mb-0">
                                    Evaluate the financial responsibility of candidates, especially for roles that involve handling finances or sensitive data.
                                </p>
                            </div>
                        </Col>
                        <Col md={12} lg={6} style={{ display: "flex" }}>
                            <div className="bg-white shadow-lg p-5 rounded-lg custom-service-border flex-1">
                                <h4 className="text-lg text-gray-800 font-bold mb-1">
                                    Identity Verification
                                </h4>
                                <p className="text-gray-800 mb-0">
                                    Verify the identity of the individual using government-issued documents such as passports, driver’s licenses, or social security numbers.
                                </p>
                            </div>
                        </Col>
                        <Col md={12} lg={6} style={{ display: "flex" }}>
                            <div className="bg-white shadow-lg p-5 rounded-lg custom-service-border flex-1">
                                <h4 className="text-lg text-gray-800 font-bold mb-1">
                                    Reference Checks
                                </h4>
                                <p className="text-gray-800 mb-0">
                                    Contact previous employers or professional references to gain insight into the candidate’s work ethic, behavior, and performance.
                                </p>
                            </div>
                        </Col>
                        <Col md={12} lg={6} style={{ display: "flex" }}>
                            <div className="bg-white shadow-lg p-5 rounded-lg custom-service-border flex-1">
                                <h4 className="text-lg text-gray-800 font-bold mb-1">
                                    Drug Testing
                                </h4>
                                <p className="text-gray-800 mb-0">
                                    Screen for the use of illegal substances to ensure a safe and productive work environment.
                                </p>
                            </div>
                        </Col>
                        <Col md={12} lg={6} style={{ display: "flex" }}>
                            <div className="bg-white shadow-lg p-5 rounded-lg custom-service-border flex-1">
                                <h4 className="text-lg text-gray-800 font-bold mb-1">
                                    Social Media Screening
                                </h4>
                                <p className="text-gray-800 mb-0">
                                    Analyze the individual’s online behavior to assess personality traits and potential red flags that may affect your business.
                                </p>
                            </div>
                        </Col>
                        <Col md={12} lg={6} style={{ display: "flex" }}>
                            <div className="bg-white shadow-lg p-5 rounded-lg custom-service-border flex-1">
                                <h4 className="text-lg text-gray-800 font-bold mb-1">
                                    Motor Vehicle Record (MVR) Checks
                                </h4>
                                <p className="text-gray-800 mb-0">
                                    Verify driving history for positions that require operating company vehicles or transporting goods.
                                </p>
                            </div>
                        </Col>

                    </Row>

                </div>
            </div>
            <div className="container padding-mob mx-auto flex items-center flex-wrap mt-10 mb-5">
                <div className="w-full md:w-1/1 mb-5">
                    <h4 className="text-3xl text-gray-800 font-bold mb-0 pt-10 pb-5 section-heading">
                        <span className="border-b-2 border-blue-600 uppercase">How It Works</span>
                    </h4>
                </div>
                <div className="w-full lg:w-1/4 md:1/2">
                    <div>
                        <p className="text-5xl m-0 mb-2 text-center flex items-center justify-center">
                            <FaRegCircleCheck color="#24357C" className="mt-1" />
                        </p>
                        <h4 className="text-lg text-gray-800 font-bold mb-1 text-center">
                            Submit Candidate Information
                        </h4>
                        <p className="text-gray-800 mb-0 text-center">
                            Provide us with the name and details of the individual you wish to screen.
                        </p>
                    </div>
                </div>
                <div className="w-full lg:w-1/4 md:1/2">
                    <div>
                        <p className="text-5xl m-0 mb-2 text-center flex items-center justify-center">
                            <FaRegCircleCheck color="#24357C" className="mt-1" />
                        </p>
                        <h4 className="text-lg text-gray-800 font-bold mb-1 text-center">
                            Advanced Database Search
                        </h4>
                        <p className="text-gray-800 mb-0 text-center">
                            We scan government, corporate, and public records to provide accurate results.
                        </p>
                    </div>
                </div>
                <div className="w-full lg:w-1/4 md:1/2">
                    <div>
                        <p className="text-5xl m-0 mb-2 text-center flex items-center justify-center">
                            <FaRegCircleCheck color="#24357C" className="mt-1" />
                        </p>
                        <h4 className="text-lg text-gray-800 font-bold mb-1 text-center">
                            Comprehensive Background Analysis
                        </h4>
                        <p className="text-gray-800 mb-0 text-center">
                            Our team conducts a thorough search across trusted databases to gather accurate information.
                        </p>
                    </div>
                </div>
                <div className="w-full lg:w-1/4 md:1/2">
                    <div>
                        <p className="text-5xl m-0 mb-2 text-center flex items-center justify-center">
                            <FaRegCircleCheck color="#24357C" className="mt-1" />
                        </p>
                        <h4 className="text-lg text-gray-800 font-bold mb-1 text-center">
                            Receive a Detailed Report
                        </h4>
                        <p className="text-gray-800 mb-0 text-center">
                            You’ll receive a comprehensive report with insights into the individual’s history, helping you make an informed decision.
                        </p>
                    </div>
                </div>
            </div>
            <div className="bz-custom-section p-12" style={{ backgroundImage: `url(${BGImg})` }}>
                <div className="overlay">
                    <div className="container mx-auto h-100 padding-mob">
                        <div className="content h-100">
                            <div className="flex items-center flex-wrap h-100">
                                <div className="w-full md:w-1/2 pr-0 lg:pr-10 text-left"></div>
                                <div className="w-full md:w-1/2 pr-0 lg:pr-10 text-left">
                                    <h4 className="text-4xl text-white font-bold mb-3 pt-10 pb-3 section-heading">
                                        <span className="border-b-2 border-white uppercase">
                                            Why It Is Important?
                                        </span>
                                    </h4>
                                    <div className="flex items-start mb-4 icon-mob-fix">
                                        <div className="mt-1">
                                            <RiCheckDoubleFill size={25} color="#fff" />
                                        </div>
                                        <p className="m-0 text-white text-lg ml-2">
                                            Ensure you hire trustworthy employees.
                                        </p>
                                    </div>
                                    <div className="flex items-start mb-4 icon-mob-fix">
                                        <div className="mt-1">
                                            <RiCheckDoubleFill size={25} color="#fff" />
                                        </div>
                                        <p className="m-0 text-white text-lg ml-2">
                                            Protect your business reputation.
                                        </p>
                                    </div>
                                    <div className="flex items-start mb-4 icon-mob-fix">
                                        <div className="mt-1">
                                            <RiCheckDoubleFill size={25} color="#fff" />
                                        </div>
                                        <p className="m-0 text-white text-lg ml-2">
                                            Create a safe and secure workplace.
                                        </p>
                                    </div>
                                    <div className="flex items-start mb-4 icon-mob-fix">
                                        <div className="mt-1">
                                            <RiCheckDoubleFill size={25} color="#fff" />
                                        </div>
                                        <p className="m-0 text-white text-lg ml-2">
                                            Comply with industry regulations.
                                        </p>
                                    </div>
                                    <div className="flex items-start mb-4 icon-mob-fix">
                                        <div className="mt-1">
                                            <RiCheckDoubleFill size={25} color="#fff" />
                                        </div>
                                        <p className="m-0 text-white text-lg ml-2">
                                            Verify professional qualifications.
                                        </p>
                                    </div>
                                    <div className="flex items-start mb-4 icon-mob-fix">
                                        <div className="mt-1">
                                            <RiCheckDoubleFill size={25} color="#fff" />
                                        </div>
                                        <p className="m-0 text-white text-lg ml-2">
                                            Build trust in partnerships.
                                        </p>
                                    </div>
                                    <div className="flex items-start mb-4 icon-mob-fix last-item">
                                        <div className="mt-1">
                                            <RiCheckDoubleFill size={25} color="#fff" />
                                        </div>
                                        <p className="m-0 text-white text-lg ml-2">
                                            Safeguard your business investments.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container padding-mob mx-auto flex flex-wrap">
                <div className="w-full md:w-1/1 mb-5">
                    <h4 className="text-3xl text-gray-800 font-bold mb-0 pt-10 pb-5 section-heading">
                        <span className="border-b-2 border-blue-600 uppercase">
                            Benefits of a Business Background Check
                        </span>
                    </h4>
                </div>
                <div className="w-full md:w-1/4  mx-auto text-center">
                    <div className="p-5">
                        <div className="flex flex-column justify-center items-center">
                            <span className="text-5xl">
                                <PiWarningFill color="#24357C" />
                            </span>
                        </div>
                        <h4 className="text-lg text-gray-800 font-bold text-center">
                            Mitigate Risk
                        </h4>
                        <div className="mt-2">
                            <p className="text-gray-800 mb-0">
                                By uncovering any potential red flags in a company’s history, you can avoid making costly mistakes or entering
                                into relationships with businesses that may harm your reputation or bottom line.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/4  mx-auto text-center">
                    <div className="p-5">
                        <div className="flex flex-column justify-center items-center">
                            <span className="text-5xl">
                                <FaClipboardCheck color="#24357C" className="mt-1" />
                            </span>
                        </div>
                        <h4 className="text-lg text-gray-800 font-bold text-center">
                            Ensure Compliance
                        </h4>
                        <div className="mt-2">
                            <p className="text-gray-800 mb-0">
                                Ensure that the companies you work with are compliant with industry regulations and standards.
                                This helps protect your business from legal issues and penalties.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/4  mx-auto text-center">
                    <div className="p-5">
                        <div className="flex flex-column justify-center items-center">
                            <span className="text-5xl">
                                <FaHandshake color="#24357C" className="mt-1" />
                            </span>
                        </div>
                        <h4 className="text-lg text-gray-800 font-bold text-center">
                            Build Strong Relationships
                        </h4>
                        <div className="mt-2">
                            <p className="text-gray-800 mb-0">
                                A business background check helps establish a foundation of trust by verifying the credentials and
                                reliability of your partners, vendors, and clients.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/4  mx-auto text-center">
                    <div className="p-5">
                        <div className="flex flex-column justify-center items-center">
                            <span className="text-5xl">
                                <HiClipboardDocumentList color="#24357C" />
                            </span>
                        </div>
                        <h4 className="text-lg text-gray-800 font-bold text-center">
                            Informed Decision-Making
                        </h4>
                        <div className="mt-2">
                            <p className="text-gray-800 mb-0">
                                Gain peace of mind by basing your business decisions on reliable, accurate, and up-to-date information, leading to more confident and informed choices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container padding-mob mx-auto flex items-center flex-wrap mt-10 mb-10">
                <div className="w-full md:w-1/1 mb-5">
                    <h4 className="text-3xl text-gray-800 font-bold pt-5 section-heading">
                        <span className="border-b-2 border-blue-600 uppercase">FAQ</span>
                    </h4>
                    <FAQs />
                </div>
            </div>
        </>
    )
}