import { Spin } from 'antd';
import { useSelector } from 'react-redux';

function Loader() {
    const isLoading = useSelector((state) => state.appSlice.isLoading);
    return (
        <>
            {isLoading ? (
                <div className='loader-container'>
                    <Spin spinning={true} size="large" color="#fff" tip="Please Wait..." />
                </div>
            ) : null}

        </>
    );
}

export default Loader;