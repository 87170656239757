import React, { useState, useEffect } from 'react';
import backgroundImage from '../../../Images/test-bg.webp';
import { personalCheckTestimonials } from '../../../utils/commonJSON';


const TestimonialSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    // Function to move to the next slide
    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === personalCheckTestimonials.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        if (!isPaused) {
            const interval = setInterval(nextSlide, 3000); // Change slide every 3 seconds
            return () => clearInterval(interval);
        }
    }, [isPaused, currentIndex]);

    return (
        <>
            <section className='testimonail-container padding-mob'
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                }}
            >
                <h1 className='testimonial-heading section-heading'>What Our Customers Are Saying</h1>
                <div
                    className="testimonial-slider"
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                >
                    {personalCheckTestimonials.map((testimonial, index) => (
                        <div
                            key={index}
                            className={`slide ${index === currentIndex ? "active" : ""}`}
                        >
                            <img
                                src={testimonial.image}
                                alt={`${testimonial.name}'s testimonial`}
                                className="testimonial-image"
                            />
                            <p className="testimonial-content">"{testimonial.content}"</p>
                            <h3 className="testimonial-name">- {testimonial.name}</h3>
                        </div>
                    ))}
                    <div className="dots">
                        {personalCheckTestimonials.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentIndex ? "active" : ""}`}
                                onClick={() => setCurrentIndex(index)}
                            ></span>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default TestimonialSlider;
