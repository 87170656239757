// src/components/PersonalInformationForm.js
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updatePersonalInformation } from '../../../redux/slice/personalBackgrountCheckSlice';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(weekday);
dayjs.extend(localeData);

const { Option } = Select;
const PersonalInformationSchema = Yup.object().shape({
    personalInformation: Yup.object().shape({
        lastName: Yup.string().required('Field is required'),
        givenName: Yup.string().required('Field is required'),
        lastNameAtBirth: Yup.string().required('Field is required'),
        formerName: Yup.string().required('Field is required'),
        email: Yup.string().email('Invalid email').required('Field is required'),
        phoneNumber: Yup.string().required('Field is required'),
        birthPlace: Yup.string().required('Field is required'),
        dob: Yup.date().required('Field is required').typeError('Invalid date').max(dayjs().subtract(18, 'years').toDate(), 'You must be at least 18 years old'),
        gender: Yup.string().required('Field is required'),
    }),
});

const PersonalInformationForm = ({ nextStep }) => {
    const dispatch = useDispatch();
    const personalInformation = useSelector((state) => state.backgroundCheck.personalInformation);

    const handleSubmit = (values) => {
        dispatch(updatePersonalInformation(values.personalInformation));
        nextStep();
    };

    return (
        <>
            <Formik
                initialValues={{
                    personalInformation: {
                        lastName: personalInformation.lastName || '',
                        givenName: personalInformation.givenName || '',
                        lastNameAtBirth: personalInformation.lastNameAtBirth || '',
                        formerName: personalInformation.formerName || '',
                        email: personalInformation.email || '',
                        phoneNumber: personalInformation.phoneNumber || '',
                        birthPlace: personalInformation.birthPlace || '',
                        dob: personalInformation.dob || '',
                        gender: personalInformation.gender || '',
                    },
                }}
                validationSchema={PersonalInformationSchema}
                onSubmit={handleSubmit}
            >

                {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue, setFieldTouched }) => (
                    <Form>
                        {/* <!-- Card Header --> */}
                        <div className="px-6 py-4 bg-gray-20 border-b border-gray-300">
                            <h2 className="text-xl font-semibold text-gray-800 mb-0">
                                Personal Information

                            </h2>
                        </div>
                        {/* <!-- Card Body --> */}
                        <div className="px-6 py-4 bg-gray-20">
                            <div className="flex flex-wrap -mx-3">
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="lastName">
                                        Surname (Last Name)
                                    </label>
                                    <input
                                        id="lastName"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="personalInformation.lastName"
                                        value={values.personalInformation.lastName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.personalInformation?.lastName && touched.personalInformation?.lastName && <p className="text-red-500 text-xs italic">{errors.personalInformation?.lastName}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="givenName">
                                        Given Name(s)
                                    </label>
                                    <input
                                        id="givenName"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="personalInformation.givenName"
                                        value={values.personalInformation.givenName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.personalInformation?.givenName && touched.personalInformation?.givenName && <p className="text-red-500 text-xs italic">{errors.personalInformation?.givenName}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="lastNameAtBirth">
                                        Surname (Last Name) at birth
                                    </label>
                                    <input
                                        id="lastNameAtBirth"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="personalInformation.lastNameAtBirth"
                                        value={values.personalInformation.lastNameAtBirth}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.personalInformation?.lastNameAtBirth && touched.personalInformation?.lastNameAtBirth && <p className="text-red-500 text-xs italic">{errors.personalInformation?.lastNameAtBirth}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="formerName">
                                        Former Name(s)
                                    </label>
                                    <input
                                        id="formerName"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="personalInformation.formerName"
                                        value={values.personalInformation.formerName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.personalInformation?.formerName && touched.personalInformation?.formerName && <p className="text-red-500 text-xs italic">{errors.personalInformation?.formerName}</p>}
                                </div>
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        id="email"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="personalInformation.email"
                                        value={values.personalInformation.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.personalInformation?.email && touched.personalInformation?.email && <p className="text-red-500 text-xs italic">{errors.personalInformation?.email}</p>}
                                </div>
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="phoneNumber">
                                        Phone Number
                                    </label>
                                    <input
                                        id="phoneNumber"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="personalInformation.phoneNumber"
                                        value={values.personalInformation.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.personalInformation?.phoneNumber && touched.personalInformation?.phoneNumber && <p className="text-red-500 text-xs italic">{errors.personalInformation?.phoneNumber}</p>}
                                </div>
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="birthPlace">
                                        Place of Birth
                                    </label>
                                    <input
                                        id="birthPlace"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="personalInformation.birthPlace"
                                        value={values.personalInformation.birthPlace}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.personalInformation?.birthPlace && touched.personalInformation?.birthPlace && <p className="text-red-500 text-xs italic">{errors.personalInformation?.birthPlace}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="dob">
                                        Date of Birth
                                    </label>
                                    <DatePicker
                                        id="dob"
                                        className="custom-datepicker appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        name="personalInformation.dob"
                                        value={values.personalInformation.dob ? dayjs(values.personalInformation.dob) : null}
                                        onChange={(date, dateString) => setFieldValue('personalInformation.dob', dateString)}
                                        onBlur={() => setFieldTouched('personalInformation.dob', true)}
                                        format="YYYY-MM-DD"
                                    />

                                    {/* <input
                                        id="dob"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="date"
                                        name="personalInformation.dob"
                                        value={values.personalInformation.dob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    /> */}
                                    {errors.personalInformation?.dob && touched.personalInformation?.dob && <p className="text-red-500 text-xs italic">{errors.personalInformation?.dob}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="gender">
                                        Gender
                                    </label>
                                    <Select
                                        id="gender"
                                        className="custom-select appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="date"
                                        name="personalInformation.gender"
                                        value={values.personalInformation.gender}
                                        onChange={(value) => setFieldValue('personalInformation.gender', value)}
                                        onBlur={() => setFieldTouched('personalInformation.gender', true)}
                                    >
                                        <Option value="">Select Gender</Option>
                                        <Option value="male">Male</Option>
                                        <Option value="female">Female</Option>
                                        <Option value="other">Other</Option>
                                    </Select>
                                    {/* <select
                                        id="gender"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="date"
                                        name="personalInformation.gender"
                                        value={values.personalInformation.gender}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select> */}
                                    {errors.personalInformation?.gender && touched.personalInformation?.gender && <p className="text-red-500 text-xs italic">{errors.personalInformation?.gender}</p>}
                                </div>
                            </div>
                        </div>

                        {/* <!-- Card Footer --> */}
                        <div className="px-6 py-4 bg-gray-20 border-t border-gray-300">
                            <button type="submit" className="bgPrimaryColor uppercase text-white rounded-full px-8 py-3 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden">
                                Next
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PersonalInformationForm;
