export const personalCheckGoogleReviews = [
    {
        name: "Alice Johnson",
        reviewText: "Amazing service! The staff was friendly and very helpful.",
        rating: 5,
        image: "https://via.placeholder.com/50", // Replace with actual profile image URL
    },
    {
        name: "Michael Lee",
        reviewText: "Good experience overall, but the waiting time was long.",
        rating: 4,
        image: "https://via.placeholder.com/50",
    },
    {
        name: "Sophia Brown",
        reviewText: "Highly recommend! Very professional and prompt.",
        rating: 5,
        image: "https://via.placeholder.com/50",
    },
];

export const personalCheckTestimonials = [
    {
        name: "John Doe",
        content: "This service was fantastic! It changed my life.",
        image: "https://via.placeholder.com/150", // Replace with actual image URL
    },
    {
        name: "Jane Smith",
        content: "Incredible experience, will definitely use again!",
        image: "https://via.placeholder.com/150", // Replace with actual image URL
    },
    {
        name: "Sam Wilson",
        content: "I couldn't be happier with the results!",
        image: "https://via.placeholder.com/150", // Replace with actual image URL
    },
];

export const personalCheckFAQ = [
    {
        question: "Is it legal to do a personal criminal background check?",
        answer: "Yes, it is entirely legal as long as you are conducting a background check for lawful reasons."
    },
    {
        question: "How long does it take to get the results?",
        answer: "Most of our criminal background checks are completed within minutes, depending on the records requested."
    },
    {
        question: "How accurate is the information?",
        answer: "We source our data from national databases, making our results as accurate and reliable as possible."
    },
    {
        question: "Can I check my own criminal records?",
        answer: "Yes, you can perform a self-check to ensure your public records are correct and up to date."
    },
    {
        question: "Are my details secure during the process?",
        answer: "Absolutely! We use encrypted systems to protect your personal and financial information."
    }
];

export const businessCheckFAQ = [
    {
        question: "Why should my business perform background checks?",
        answer: "Background checks reduce the risk of hiring individuals with a questionable history, helping protect your company from liability, fraud, and reputational damage."
    },
    {
        question: "Is it legal to perform background checks on employees or applicants?",
        answer: "Yes, it is legal to perform background checks, provided you comply with local labor laws and obtain the individual’s consent."
    },
    {
        question: "How long does it take to get results?",
        answer: "Most background checks are completed within 24-72 hours, depending on the depth and complexity of the screening process."
    },
    {
        question: "Can I perform checks on existing employees?",
        answer: "Yes, businesses often conduct background checks for promotions or sensitive roles to ensure the continued trustworthiness of their workforce."
    },
    {
        question: "Are my results confidential?",
        answer: "Absolutely. We use secure, encrypted systems to ensure your data and the candidate's information remain private and protected."
    }
];

// export const emailForPDF = "Info@metaforcecareers.ca";
// export const emailForPDF = "sheikhsalman1472@gmail.com";
export const emailForPDF = "scheduling@metaforcecareers.ca";
