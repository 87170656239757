import { updateCriminalRecord, updateCurrentAddress, updateInformedConsent, updatePersonalInformation, updatePreviousAddress, updateReasonForCRV, updateIdVerification } from '../../../redux/slice/personalBackgrountCheckSlice';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Upload, message, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { emailForPDF } from '../../../utils/commonJSON';
import axios from 'axios';
import { setIsLoading } from '../../../redux/slice/appSlice';
import PdfGenerator from './pdfGenerator';


const IdVerificationSchema = Yup.object().shape({
    idVerification: Yup.object().shape({
        idOne: Yup.string().required('ID One is required'),
        idTwo: Yup.string().required('ID Two is required'),
    }),
});

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function IdVerification({ prevStep, gofirstStep }) {
    const [triggerPdf, setTriggerPdf] = useState(false);

    const dispatch = useDispatch();
    const idVerification = useSelector((state) => state.backgroundCheck.idVerification);
    const isLoading = useSelector((state) => state.isLoading);

    const initialValues = {
        idVerification: {
            idOne: idVerification?.idOne || '',
            idTwo: idVerification?.idTwo || '',
            additionalId: idVerification?.additionalId || '',
        },
    };

    const handleUpload = async (info, setFieldValue, fieldName) => {
        const file = info.file.originFileObj || info.file;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt3M = file.size / 1024 / 1024 < 3;

        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG files!');
            return;
        }
        if (!isLt3M) {
            message.error('File must be smaller than 3MB!');
            return;
        }

        try {
            const base64 = await getBase64(file);
            setFieldValue(`idVerification.${fieldName}`, base64);
            dispatch(updateIdVerification({
                ...idVerification,
                [fieldName]: base64,
            }));
        } catch (error) {
            console.error('Error converting file to Base64:', error);
        }
    };


    const handleSubmit = (values) => {
        dispatch(setIsLoading(true));
        dispatch(updateIdVerification(values.idVerification));
        setTriggerPdf(true);
        console.log('Submitted Values:', values);
    };

    const handlePdfGenerated = async (PDFbase64) => {
        await sendEmail(PDFbase64);
    };

    const sendEmail = async (PDFbase64) => {
        const attachments = [
            {
                name: 'form-submission.pdf',
                content: PDFbase64,
                type: 'application/pdf',
            },
        ];

        const files = [
            { name: 'ID-One.jpg', content: idVerification.idOne },
            { name: 'ID-Two.jpg', content: idVerification.idTwo },
            { name: 'Additional-ID.jpg', content: idVerification.additionalId },
        ];

        files.forEach((file) => {
            if (file.content) {
                attachments.push({
                    name: file.name,
                    content: file.content.split(',')[1],
                    type: file.content.split(';')[0].split(':')[1] || 'image/jpeg',
                });
            }
        });

        const emailPayload = {
            sender: {
                name: 'Meta Force Careers',
                email: 'scheduling@metaforcecareers.ca',
            },
            to: [
                {
                    email: emailForPDF,
                    name: 'Harwinder Singh',
                },
            ],
            subject: 'Meta Force Background Check Form',
            htmlContent: `<p>Thank you for your submission! Please find the details attached.</p>`,
            attachment: attachments,
        };

        console.log("idVerification => ", idVerification);

        const config = {
            method: 'post',
            url: 'https://api.brevo.com/v3/smtp/email',
            headers: {
                accept: 'application/json',
                'api-key': process.env.REACT_APP_API_KEY,
                'content-type': 'application/json',
            },
            data: JSON.stringify(emailPayload),
        };

        try {
            const response = await axios.request(config);
            message.success('Form is submitted successfully!');
            console.log('Email response:', response.data);
        } catch (error) {
            message.error('Failed to submit the form. Please try again.');
            console.error('Error sending email:', error);
        } finally {
            dispatch(setIsLoading(false));
            resetForm();
        }
    };

    const resetForm = () => {
        setTriggerPdf(false);

        dispatch(updatePersonalInformation({
            lastName: '',
            givenName: '',
            lastNameAtBirth: '',
            formerName: '',
            email: '',
            phoneNumber: '',
            birthPlace: '',
            dob: '',
            gender: ''
        }));
        dispatch(updateCurrentAddress({
            streetNumber: '',
            streetName: '',
            unitNumber: '',
            city: '',
            province: '',
            postalCode: '',
            country: ''
        }));
        dispatch(updatePreviousAddress([
            {
                streetNumber: '',
                streetName: '',
                unitNumber: '',
                city: '',
                province: '',
                postalCode: '',
                country: ''
            }
        ]));
        dispatch(updateReasonForCRV({
            reasonForRequest: '',
            organizationRequestingSearch: '',
            contactName: '',
            contactPhone: ''
        }));
        dispatch(updateCriminalRecord({
            lastName: '',
            givenName: '',
            dob: '',
            offenceDetails: [{
                offence: '',
                dos: '',
                courtLocation: ''
            }]
        }));
        dispatch(updateInformedConsent({
            searchAuthorization: false,
            policeInformationSystems: false,
            policeInfoSystems: {
                CPICData: false,
                PIO: false,
                other: false,
            },
            AuthorizationWaiver: false,
            metaforceConsentSystems: false,
            ThirdPartyOrg: '',
            SignedAt: '',
            dateSigned: '',
            applicantSignature: '',
        }));
        dispatch(updateIdVerification({
            idOne: '',
            idTwo: '',
            additionalId: ''
        }));
        gofirstStep(1);
    };


    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={IdVerificationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, validateField }) => (
                    <Form>
                        <div className="px-6 py-4 bg-gray-20 border-b border-gray-300">
                            <h2 className="text-xl font-semibold text-gray-800 mb-0">
                                ID(s) Verification
                            </h2>
                        </div>

                        <div className="px-6 py-4 bg-gray-20">
                            {/* ID One */}
                            <div className="flex flex-wrap -mx-3 mb-5">
                                <div className="w-full md:w-1/4 px-3">
                                    <label
                                        htmlFor="idVerification.idOne"
                                        className="block text-gray-700 text-sm font-bold mb-2 ml-2"
                                    >
                                        ID One
                                    </label>
                                    <Upload
                                        name="idOne"
                                        beforeUpload={() => false}
                                        onChange={(info) =>
                                            handleUpload(info, setFieldValue, 'idOne').then(() =>
                                                validateField('idVerification.idOne')
                                            )
                                        }
                                        showUploadList={false}
                                    >
                                        <button
                                            type="button"
                                            className="upload-btn bg-gray-50 border rounded-full px-8 py-3 leading-tight focus:outline-none focus:bg-white mb-1"
                                        >
                                            <UploadOutlined /> Upload ID One
                                        </button>
                                    </Upload>
                                </div>
                                <div className="w-full md:w-3/4 px-3">
                                    {values.idVerification.idOne && (
                                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                            <Image
                                                src={values.idVerification.idOne}
                                                alt="ID One Preview"
                                                width={150}
                                                style={{
                                                    borderRadius: '8px',
                                                    border: '1px solid #d9d9d9',
                                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                                                    padding: '5px',
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="w-full md:w-1/1 px-3">
                                    <Field type="hidden" name="idVerification.idOne" />
                                    <ErrorMessage
                                        name="idVerification.idOne"
                                        component="div"
                                        className="text-red-500 text-xs italic ml-2"
                                    />
                                </div>
                            </div>
                            {/* ID Two */}
                            <div className="flex flex-wrap -mx-3 mb-5">
                                <div className="w-full md:w-1/4 px-3">
                                    <label
                                        htmlFor="idVerification.idTwo"
                                        className="block text-gray-700 text-sm font-bold mb-2 ml-2"
                                    >
                                        ID Two
                                    </label>
                                    <Upload
                                        name="idTwo"
                                        beforeUpload={() => false}
                                        onChange={(info) =>
                                            handleUpload(info, setFieldValue, 'idTwo').then(() =>
                                                validateField('idVerification.idTwo')
                                            )
                                        }
                                        showUploadList={false}
                                    >
                                        <button
                                            type="button"
                                            className="upload-btn bg-gray-50 border rounded-full px-8 py-3 leading-tight focus:outline-none focus:bg-white mb-1"
                                        >
                                            <UploadOutlined /> Upload ID Two
                                        </button>
                                    </Upload>
                                </div>
                                <div className="w-full md:w-3/4 px-3">
                                    {values.idVerification.idTwo && (
                                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                            <Image
                                                src={values.idVerification.idTwo}
                                                alt="ID One Preview"
                                                width={150}
                                                style={{
                                                    borderRadius: '8px',
                                                    border: '1px solid #d9d9d9',
                                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                                                    padding: '5px',
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="w-full md:w-1/1 px-3">
                                    <Field type="hidden" name="idVerification.idTwo" />
                                    <ErrorMessage
                                        name="idVerification.idTwo"
                                        component="div"
                                        className="text-red-500 text-xs italic ml-2"
                                    />
                                </div>
                            </div>
                            {/* Addtional ID */}
                            <div className="flex flex-wrap -mx-3 mb-5">
                                <div className="w-full md:w-1/4 px-3">
                                    <label
                                        htmlFor="idVerification.additionalId"
                                        className="block text-gray-700 text-sm font-bold mb-2 ml-2"
                                    >
                                        Additional ID
                                    </label>
                                    <Upload
                                        name="additionalId"
                                        beforeUpload={() => false}
                                        onChange={(info) =>
                                            handleUpload(info, setFieldValue, 'additionalId')
                                        }
                                        showUploadList={false}
                                    >
                                        <button
                                            type="button"
                                            className="upload-btn bg-gray-50 border rounded-full px-8 py-3 leading-tight focus:outline-none focus:bg-white mb-1"
                                        >
                                            <UploadOutlined /> Upload Additional ID
                                        </button>
                                    </Upload>
                                </div>
                                <div className="w-full md:w-3/4 px-3">
                                    {values.idVerification.additionalId && (
                                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                            <Image
                                                src={values.idVerification.additionalId}
                                                alt="Additional ID Preview"
                                                width={150}
                                                style={{
                                                    borderRadius: '8px',
                                                    border: '1px solid #d9d9d9',
                                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                                                    padding: '5px',
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="w-full md:w-1/1 px-3">
                                    <Field type="hidden" name="idVerification.additionalId" />
                                </div>
                            </div>
                        </div>

                        {/* Navigation Buttons */}
                        <div className="px-6 py-4 bg-gray-20 border-t border-gray-300 flex justify-between">
                            <button
                                type="submit"
                                className="bgPrimaryColor uppercase text-white rounded-full px-8 py-3 text-center md:text-left hover:bg-blue-800 transform transition duration-500 relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                className="bg-gray-300 text-dark rounded-full px-8 py-3 shadow hover:bg-gray-300"
                                onClick={prevStep}
                            >
                                Back
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            <PdfGenerator triggerPdf={triggerPdf} onPdfGenerated={handlePdfGenerated} />
        </>
    );
}

export default IdVerification;
