import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateInformedConsent } from '../../../redux/slice/personalBackgrountCheckSlice';
import SignatureCanvas from 'react-signature-canvas';
import { Button, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);

const InformedConsentSchema = Yup.object().shape({
    informedConsent: Yup.object().shape({
        searchAuthorization: Yup.boolean().oneOf([true], 'Field is required'),
        policeInformationSystems: Yup.boolean().oneOf([true], 'Field is required'),
        policeInfoSystems: Yup.object().shape({
            CPICData: Yup.boolean(),
            PIO: Yup.boolean(),
            other: Yup.boolean(),
        }).test(
            'at-least-one',
            'At least one field is required',
            (values) => {
                const { CPICData, PIO, other } = values || {};
                return CPICData || PIO || other;
            }
        ),
        AuthorizationWaiver: Yup.boolean().oneOf([true], 'Field is required'),
        metaforceConsentSystems: Yup.boolean().oneOf([true], 'Field is required'),
        // ThirdPartyOrg: Yup.string().required('Field is required'),
        SignedAt: Yup.string().required('Field is required'),
        dateSigned: Yup.string().required('Field is required'),
        applicantSignature: Yup.string().required('Field is required'),
    }),
});


function InformedConsentForm({ prevStep, nextStep }) {
    // local state and ref 
    const signaturePadRef = useRef(null);

    // redux state and method
    const dispatch = useDispatch();
    const informedConsent = useSelector((state) => state.backgroundCheck.informedConsent || {});

    // for signatire pad
    useEffect(() => {
        // If informedConsent.applicantSignature exists, load it into the signature pad (if any)
        if (informedConsent.applicantSignature && signaturePadRef.current) {
            signaturePadRef.current.fromDataURL(informedConsent.applicantSignature);
        }
    }, [informedConsent]);

    const handleCheckboxChange = (e, checkboxName, setFieldValue) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            // If one checkbox is checked, check all three
            setFieldValue('informedConsent.policeInfoSystems.CPICData', true);
            setFieldValue('informedConsent.policeInfoSystems.PIO', true);
            setFieldValue('informedConsent.policeInfoSystems.other', true);
        } else {
            // Uncheck the specific checkbox
            setFieldValue(`informedConsent.policeInfoSystems.${checkboxName}`, false);
        }
    };



    const handleSubmit = (values) => {
        // Check if the signature is empty before submission

        if (signaturePadRef.current && signaturePadRef.current.isEmpty()) {
            message.error('Please provide your signature before submitting.');
            return;
        }
        if (signaturePadRef.current) {
            const signatureDataURL = signaturePadRef.current.toDataURL();
            values.informedConsent.applicantSignature = signatureDataURL;
        }
        dispatch(updateInformedConsent(values.informedConsent));
        nextStep();
    };


    return (
        <>
            <Formik
                initialValues={{
                    informedConsent: {
                        searchAuthorization: informedConsent.searchAuthorization || false,
                        policeInformationSystems: informedConsent.policeInformationSystems || false,
                        policeInfoSystems: {
                            CPICData: informedConsent.policeInfoSystems.CPICData || false,
                            PIO: informedConsent.policeInfoSystems.PIO || false,
                            other: informedConsent.policeInfoSystems.other || false,
                        },
                        AuthorizationWaiver: informedConsent.AuthorizationWaiver || false,
                        metaforceConsentSystems: informedConsent.metaforceConsentSystems || false,
                        ThirdPartyOrg: informedConsent.ThirdPartyOrg || '',
                        SignedAt: informedConsent.SignedAt || '',
                        dateSigned: informedConsent.dateSigned || dayjs().format('YYYY-MM-DD'),
                        applicantSignature: informedConsent.applicantSignature || '',
                    },
                }}
                validationSchema={InformedConsentSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
                    <Form>
                        <div className="px-6 py-4 bg-gray-20 border-b border-gray-300">
                            <h2 className="text-xl font-semibold text-gray-800 mb-0">
                                Informed Consent
                            </h2>
                        </div>

                        <div className="px-6 py-4 bg-gray-20">
                            <div className="flex flex-wrap -mx-3">
                                {/* Search Authorization */}
                                <div className="w-full md:w-1/1 px-3 mb-5">
                                    <label className="container-checkbox block tracking-wide text-gray-700">
                                        Search Authorization: I HEREBY CONSENT TO THE SEARCH OF THE RCMP National Repository of Criminal Records based on the name(s),
                                        date of birth, and where used, the declared criminal record history provided by myself. I understand that this verification of
                                        the National Repository of Criminal Records is not being confirmed by fingerprint comparison which is the only true means by
                                        which to confirm if a criminal record exists in the National Repository of Criminal Records.
                                        <input
                                            type="checkbox"
                                            name="informedConsent.searchAuthorization"
                                            checked={values.informedConsent.searchAuthorization}
                                            onChange={handleChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    {errors.informedConsent?.searchAuthorization && touched.informedConsent?.searchAuthorization && (
                                        <p className="text-red-500 text-xs italic">{errors.informedConsent.searchAuthorization}</p>
                                    )}
                                </div>

                                {/* Police Information Systems */}
                                <div className="w-full md:w-1/1 px-3 mb-5">
                                    <label className="container-checkbox block tracking-wide text-gray-700">
                                        POLICE INFORMATION SYSTEM(S) – I HEREBY CONSENT TO THE SEARCH OF police information systems, as a part of a
                                        Police Information Check, which will consist of a search of the following systems (check applicable):
                                        <input
                                            type="checkbox"
                                            name="informedConsent.policeInformationSystems"
                                            checked={values.informedConsent.policeInformationSystems}
                                            onChange={handleChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    {errors.informedConsent?.policeInformationSystems && touched.informedConsent?.policeInformationSystems && (
                                        <p className="text-red-500 text-xs italic">{errors.informedConsent.policeInformationSystems}</p>
                                    )}
                                </div>

                                {/* <div className="w-full md:w-1/1 px-3 mb-5">
                                    <h3 className="text-lg font-medium text-gray-700">Police Information Systems</h3>
                                </div> */}
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    {/* CPICData */}
                                    <label className="container-checkbox block tracking-wide text-gray-700">
                                        CPIC Investigative Data Bank
                                        <input
                                            type="checkbox"
                                            name="informedConsent.policeInfoSystems.CPICData"
                                            checked={values.informedConsent.policeInfoSystems.CPICData}
                                            onChange={(e) => handleCheckboxChange(e, "CPICData", setFieldValue)}
                                        />

                                        <span className="checkmark"></span>
                                    </label>
                                </div>

                                {/* PIO */}
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="container-checkbox block tracking-wide text-gray-700">
                                        Police Information Portal (PIP)
                                        <input
                                            type="checkbox"
                                            name="informedConsent.policeInfoSystems.PIO"
                                            checked={values.informedConsent.policeInfoSystems.PIO}
                                            onChange={(e) => handleCheckboxChange(e, "PIO", setFieldValue)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>

                                </div>
                                {/* Other */}
                                <div className="w-full md:w-1/3 px-3 mb-5">
                                    <label className="container-checkbox block tracking-wide text-gray-700">
                                        Other
                                        <input
                                            type="checkbox"
                                            name="informedConsent.policeInfoSystems.other"
                                            checked={values.informedConsent.policeInfoSystems.other}
                                            onChange={(e) => handleCheckboxChange(e, "other", setFieldValue)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>

                                </div>
                                <div className="w-full md:w-1/1 px-3 mb-5">
                                    <ErrorMessage name="informedConsent.policeInfoSystems" component="div" className="text-red-500 text-xs italic text-center" />
                                </div>

                                {/* Police Information Systems */}
                                <div className="w-full md:w-1/1 px-3 mb-5">
                                    <label className="container-checkbox block tracking-wide text-gray-700">
                                        AUTHORIZATION AND WAIVER to provide confirmation of criminal record or any police information. I certify that the information set out
                                        by me in this application is true and correct to the best of my ability. I consent to the release of the results of the criminal record
                                        checks to METAFORCE CAREERS INC., located in MISSISSAUGA, CANADA.
                                        <input
                                            type="checkbox"
                                            name="informedConsent.AuthorizationWaiver"
                                            checked={values.informedConsent.AuthorizationWaiver}
                                            onChange={handleChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    {errors.informedConsent?.AuthorizationWaiver && touched.informedConsent?.AuthorizationWaiver && (
                                        <p className="text-red-500 text-xs italic">{errors.informedConsent.AuthorizationWaiver}</p>
                                    )}
                                </div>
                                {/* Police Information Systems */}
                                <div className="w-full md:w-1/1 px-3 mb-5">
                                    <label className="container-checkbox block tracking-wide text-gray-700">
                                        I hereby release and forever discharge all members and employees of the processing Police Service and the Royal Canadian
                                        Mounted Police from any and all actions, claims, and demands for damages, loss, or injury howsoever arising which may
                                        hereafter be sustained by myself as a result of the disclosure of information by the COBOURG POLICE SERVICE to METAFORCE
                                        CAREERS INC, MISSISSAUGA, CANADA.
                                        <input
                                            type="checkbox"
                                            name="informedConsent.metaforceConsentSystems"
                                            checked={values.informedConsent.metaforceConsentSystems}
                                            onChange={handleChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    {errors.informedConsent?.metaforceConsentSystems && touched.informedConsent?.metaforceConsentSystems && (
                                        <p className="text-red-500 text-xs italic">{errors.informedConsent.metaforceConsentSystems}</p>
                                    )}
                                </div>
                                <div className="w-full md:w-1/1 px-3 mb-5">
                                    <h3 className="text-lg font-medium text-gray-700 mb-5">Third Party Organization</h3>
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="ThirdPartyOrg">
                                        Third Party Organization Name if required
                                    </label>
                                    <input
                                        id="ThirdPartyOrg"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="informedConsent.ThirdPartyOrg"
                                        value={values.informedConsent.ThirdPartyOrg}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {/* {errors.informedConsent?.ThirdPartyOrg && touched.informedConsent?.ThirdPartyOrg && <p className="text-red-500 text-xs italic">{errors.informedConsent?.ThirdPartyOrg}</p>} */}
                                </div>
                                <div className="w-full md:w-1/1 px-3 mb-5">
                                    <h3 className="text-lg font-medium text-gray-700">Signed</h3>
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="SignedAt">
                                        Signed At
                                    </label>
                                    <input
                                        id="SignedAt"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="text"
                                        name="informedConsent.SignedAt"
                                        value={values.informedConsent.SignedAt}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.informedConsent?.SignedAt && touched.informedConsent?.SignedAt && <p className="text-red-500 text-xs italic">{errors.informedConsent?.SignedAt}</p>}
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2" htmlFor="dateSigned">
                                        Date Signed
                                    </label>
                                    <DatePicker
                                        id="dob"
                                        className="custom-datepicker appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        name="informedConsent.dateSigned"
                                        value={values.informedConsent.dateSigned ? dayjs(values.informedConsent.dateSigned) : null}
                                        onChange={(date, dateString) => setFieldValue('informedConsent.dateSigned', dateString)}
                                        onBlur={() => setFieldTouched('informedConsent.dateSigned', true)}
                                        format="YYYY-MM-DD"
                                    />
                                    {/* <input
                                        id="dateSigned"
                                        className="appearance-none bg-gray-50 block w-full border rounded-full py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                        type="date"
                                        name="informedConsent.dateSigned"
                                        value={values.informedConsent.dateSigned}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    /> */}
                                    {errors.informedConsent?.dateSigned && touched.informedConsent?.dateSigned && <p className="text-red-500 text-xs italic">{errors.informedConsent?.dateSigned}</p>}
                                </div>
                                <div>
                                    <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 pl-2">Signature</label>
                                    <SignatureCanvas
                                        ref={signaturePadRef}
                                        canvasProps={{
                                            width: 500,
                                            height: 200,
                                            className: 'signature-canvas',
                                            style: { backgroundColor: '#f0f0f0' },
                                        }}
                                        // onEnd={handleSignatureEnd}
                                        onEnd={() => {
                                            const signatureImage = signaturePadRef.current.toDataURL();
                                            setFieldValue('informedConsent.applicantSignature', signatureImage);
                                        }}
                                    />
                                    <ErrorMessage name="informedConsent.applicantSignature" component="div" className="text-red-500 text-xs italic" />
                                    <Button onClick={() => signaturePadRef.current.clear()}>Clear Signature</Button>
                                </div>
                                <div className='mt-4'>
                                    <h2 className="text-md font-medium">
                                        Name and Location of the Company where information will be stored in Canada: Metaforce Careers Inc. Mississauga, Canada.
                                    </h2>
                                </div>
                                <div>
                                    <h2 className="text-md font-medium">
                                        Information related to this criminal record check is collected, retained and disclosed in accordance with applicable privacy legislation.                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="px-6 py-4 bg-gray-20 border-t border-gray-300 flex align-center justify-between">
                            <button
                                type="submit"
                                className="bgPrimaryColor uppercase text-white rounded-full px-8 py-3 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden"
                            >
                                Next
                            </button>
                            <button type="button" className="bg-gray-300 text-dark rounded-full px-8 py-3 shadow hover:bg-gray-300" onClick={() => prevStep()}>
                                Back
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default InformedConsentForm;
