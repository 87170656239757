// src/components/StepFormContainer.js
import React, { useEffect, useState } from 'react';
import PersonalInformationForm from './personalInfoForm';
import CurrentAddressForm from './currentAddressForm';
import PreviousAddressForm from './previousAddressForm';
import ReasonForRequestForm from './reasonForRequestForm';
import CriminalRecordForm from './criminalRecordForm';
import InformedConsentForm from './informedConsentForm';
import IdVerification from './idVerification';
import { useSelector } from 'react-redux';


const StepFormContainer = () => {
    const [step, setStep] = useState(1);

    const currentAddress = useSelector((state) => state.backgroundCheck.currentAddress);

    // const nextStep = () => setStep((prev) => prev + 1);
    // const prevStep = () => setStep((prev) => prev - 1);
    const nextStep = () => {
        if (step === 3 && currentAddress?.past5years) {
            setStep((prev) => prev + 1); 
        } else {
            setStep((prev) => prev + 1);
        }
    };

    const prevStep = () => {
        if (step === 4 && currentAddress?.past5years) {
            setStep(2);
        } else {
            setStep((prev) => prev - 1);
        }
    };

    const gofirstStep = () => setStep(1);

    useEffect(() => {
        if (step === 3 && currentAddress?.past5years) {
            nextStep();
        }
    }, [step, currentAddress?.past5years, nextStep]);

    return (
        <div>
            {/* <IdVerification /> */}
            {step === 1 && <PersonalInformationForm nextStep={nextStep} />}
            {step === 2 && <CurrentAddressForm nextStep={nextStep} prevStep={prevStep} />}
            {step === 3 && !currentAddress?.past5years && <PreviousAddressForm nextStep={nextStep} prevStep={prevStep} />}
            {step === 4 && <ReasonForRequestForm prevStep={prevStep} nextStep={nextStep} />}
            {step === 5 && <CriminalRecordForm prevStep={prevStep} nextStep={nextStep} />}
            {step === 6 && <InformedConsentForm prevStep={prevStep} nextStep={nextStep} />}
            {step === 7 && <IdVerification prevStep={prevStep} gofirstStep={gofirstStep} />}
        </div>
    );
};

export default StepFormContainer;
