import React from 'react';
import { Collapse } from 'antd';
import 'antd/dist/antd.css';
import { businessCheckFAQ } from '../../../utils/commonJSON';

const { Panel } = Collapse;

const FAQs = () => {
    return (
        <div style={{ maxWidth: "80%", margin: "0 auto" }}>
            <h2 className="text-lg font-medium mb-3">
                Frequently Asked Questions
            </h2>
            <Collapse accordion size="large" defaultActiveKey={['0']}>
                {businessCheckFAQ.map((item, index) => (
                    <Panel header={item.question} key={index}>
                        <p>{item.answer}</p>
                    </Panel>
                ))}
            </Collapse>
        </div>
    );
};

export default FAQs;
