import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import html2pdf from 'html2pdf.js';
import mfLogo from '../../../Images/logo/metaforce.png';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Button } from 'antd';
import { setIsLoading } from '../../../redux/slice/appSlice';

const previousAddressSample = [
    { streetNumber: '', streetName: '', unitNumber: '', city: '', province: '', postalCode: '', country: '' },
    { streetNumber: '', streetName: '', unitNumber: '', city: '', province: '', postalCode: '', country: '' },
];

const PdfGenerator = ({ triggerPdf, onPdfGenerated }) => {
    // Access form data from Redux store
    const personalInformation = useSelector((state) => state.backgroundCheck.personalInformation);
    const currentAddress = useSelector((state) => state.backgroundCheck.currentAddress);
    const previousAddress = useSelector((state) => state.backgroundCheck.previousAddress);
    const reasonForCRV = useSelector((state) => state.backgroundCheck.reasonForCRV);
    const criminalRecord = useSelector((state) => state.backgroundCheck.criminalRecord);
    const informedConsent = useSelector((state) => state.backgroundCheck.informedConsent || {});

    const dispatch = useDispatch();


    // Function to create empty address objects if necessary
    const createEmptyAddresses = (count) => {
        const emptyAddress = {
            streetNumber: '',
            streetName: '',
            unitNumber: '',
            city: '',
            province: '',
            postalCode: '',
            country: ''
        };
        return Array(count).fill(emptyAddress);
    };

    var addressTemp = [];
    if (previousAddress.length === 0) {
        addressTemp = [...createEmptyAddresses(2)];
    } else if (previousAddress.length === 1) {
        addressTemp = [...previousAddress, ...createEmptyAddresses(1)];
    } else {
        addressTemp = previousAddress.slice(0, 2);
    }

    function padOffenceDetails(details, targetLength = 6) {
        const emptyItem = { offence: '', dos: '', courtLocation: '' };
        return [...details, ...Array(Math.max(0, targetLength - details.length)).fill(emptyItem)];
    }

    // Function to generate PDF
    const generatePdf = async () => {
        let Y1 = 416;
        let Y2 = 377;
        let length = criminalRecord.offenceDetails.length;
        for (let i = 0; i < length; i++) {
            if (i > 0) {
                Y1 = Y1 - 22;
                Y2 = Y2 - 22;
            }
        }


        // Create a container for the HTML template
        const element = document.createElement('div');

        const docHeaderHTML = `
        <div class="flex items-start justify-between">
                <div>
                <img src="${mfLogo}" alt="logo" style="height:40px;"/>
                </div>
                <div>
                    <p class="fs-13px mb-0 mt-0" style="font-weight:bold;line-height:16px;text-align:right;">
                             CRIMINAL RECORD VERIFICATION
                    </p>
                    <p class="fs-13px mt-0" style="font-weight:bold; line-height:16px;text-align:right;">
                             Informed Consent Form
                    </p>
                </div>
            </div>
            `;

        const personalInfoHTML = `
            <p class="fs-14px bg-gray-300 px-1 flex items-center" style="padding-bottom:15px;height:20px;font-weight:bold; margin:0px; line-height:16px;">
                A.Personal Information
            </p>
            <div class="flex flex-wrap border-b border-gray-500">
                <div class="col-6 pb-5px pl-5px pr-5px">
                     <label class="block text-gray-700 fs-11px" >
                          Surname (Last Name): ${personalInformation.lastName}
                      </label>
                </div>
                <div class="col-6 border-l border-gray-500 pb-5px pl-5px pr-5px">
                     <label class="block text-gray-700 fs-11px" >
                          Given Name(s): ${personalInformation.givenName}
                      </label>
                </div>
            </div>
            <div class="flex flex-wrap border-b border-gray-500">
                <div class="col-6 pb-5px pl-5px pr-5px">
                     <label class="block text-gray-700 fs-11px" >
                          Surname (Last Name) at birth: ${personalInformation.lastNameAtBirth}
                      </label>
                </div>
                <div class="col-6 border-l border-gray-500 pb-5px pl-5px pr-5px">
                     <label class="block text-gray-700 fs-11px" >
                          Former name(s): ${personalInformation.formerName}
                      </label>
                </div>
            </div>
            <div class="flex flex-wrap border-b border-gray-500">
                <div class="w-100 pb-5px pl-5px pr-5px">
                     <label class="block text-gray-700 fs-11px" >
                          Place of birth (City, Province/State, Country): ${personalInformation.birthPlace}
                      </label>
                </div>
            </div>
            <div class="flex flex-wrap border-b border-gray-500">
                <div class="col-6 pb-5px pl-5px pr-5px">
                     <label class="block text-gray-700 fs-11px" >
                          Date of birth (YYYY-MM-DD): ${personalInformation.dob}
                      </label>
                </div>
                <div class="col-6 border-l border-gray-500 pb-5px pl-5px pr-5px">
                     <label class="block text-gray-700 fs-11px" >
                          Sex (check one): ${personalInformation.gender}
                      </label>
                </div>
            </div>
            <div class="flex flex-wrap border-b border-gray-500">
                <div class="col-6 pb-5px pl-5px pr-5px">
                     <label class="block text-gray-700 fs-11px" >
                          Phone number(s): ${personalInformation.phoneNumber}
                      </label>
                </div>
                <div class="col-6 border-l border-gray-500 pb-5px pl-5px pr-5px">
                     <label class="block text-gray-700 fs-11px" >
                          Email address: ${personalInformation.email}
                      </label>
                </div>
            </div>
            `;

        const currentAddressHTML = `
                    <div class="address-box current-address">
                        <div>
                            <p class="fs-11px px-1 flex items-center" style="margin:0px;">
                                 Current Home Address
                            </p>
                        </div>
                        <div class="flex flex-wrap">
                            <div style="width:8%" class="px-2">
                                <div class="border-b border-gray-500 pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="height:20px;">
                                        ${currentAddress.streetNumber}
                                     </label>
                                </div>
                                <div class="pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                        Number
                                     </label>
                                </div>
                            </div>
                            <div style="width:22%" class="px-2">
                                <div class="border-b border-gray-500 pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="height:20px;">
                                        ${currentAddress.streetName}
                                     </label>
                                </div>
                                <div class="pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                        Street
                                     </label>
                                </div>
                            </div>
                            <div style="width:10%" class="px-2">
                                <div class="border-b border-gray-500 pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="height:20px;">
                                        ${!!currentAddress.unitNumber ? currentAddress.unitNumber : ''}
                                     </label>
                                </div>
                                <div class="pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                        Apartment
                                     </label>
                                </div>
                            </div>
                            <div style="width:18%" class="px-2">
                                <div class="border-b border-gray-500 pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="height:20px;">
                                        ${currentAddress.city}
                                     </label>
                                </div>
                                <div class="pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                        City
                                     </label>
                                </div>
                            </div>
                            <div style="width:18%" class="px-2">
                                <div class="border-b border-gray-500 pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="height:20px;">
                                        ${currentAddress.province}
                                     </label>
                                </div>
                                <div class="pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                        Province/Territory/State
                                     </label>
                                </div>
                            </div>
                            <div style="width:10%" class="px-2">
                                <div class="border-b border-gray-500 pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="height:20px;">
                                        ${currentAddress.postalCode}
                                     </label>
                                </div>
                                <div class="pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                        Postal/Zip Code
                                     </label>
                                </div>
                            </div>
                            <div style="width:14%" class="px-2">
                                <div class="border-b border-gray-500 pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="height:20px;">
                                        ${currentAddress.country}
                                     </label>
                                </div>
                                <div class="pb-5px pl-5px">
                                    <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                        Country
                                     </label>
                                </div>
                            </div>
                        </div>
                    </div>
            `;

        const addressContent = addressTemp.map((address, index) => `
                    <div class="flex flex-wrap ${index > 0 && "border-b border-gray-500"}" style="${index > 0 && "padding-bottom:20px;"}">
                        <div style="width:8%" class="px-2">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    ${address.streetNumber}
                                </label>
                            </div>
                        </div>
                        <div style="width:22%" class="px-2">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    ${address.streetName}
                                </label>
                            </div>
                        </div>
                        <div style="width:10%" class="px-2">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    ${!!address.unitNumber ? address.unitNumber : ''}
                                </label>
                            </div>
                        </div>
                        <div style="width:18%" class="px-2">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    ${address.city}
                                </label>
                            </div>
                        </div>
                        <div style="width:18%" class="px-2">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    ${address.province}
                                </label>
                            </div>
                        </div>
                        <div style="width:10%" class="px-2">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    ${address.postalCode}
                                </label>
                            </div>
                        </div>
                        <div style="width:14%" class="px-2">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    ${address.country}
                                </label>
                            </div>
                        </div>
                    </div>
            `).join('');
        const previousAddressHTML = `
        <div class="address-box previous-address">
            <div>
                <p class="fs-11px px-1 flex items-center" style="margin:0px;">
                    Previous Address(es) Within the Last 5 Years (attach additional page if necessary)
                </p>
            </div>
            ${addressContent}
        </div>
            `;

        const otherAddresses = previousAddress.slice(2).map((address, index) => `
            <div class="flex flex-wrap border-b border-gray-500" style="padding-bottom:20px;">
                <div style="width:8%" class="px-2">
                    <div class="border-b border-gray-500 pb-5px pl-5px">
                        <label class="block text-gray-700 fs-11px" style="height:20px;">
                            ${address.streetNumber}
                        </label>
                    </div>
                </div>
                <div style="width:22%" class="px-2">
                    <div class="border-b border-gray-500 pb-5px pl-5px">
                        <label class="block text-gray-700 fs-11px" style="height:20px;">
                            ${address.streetName}
                        </label>
                    </div>
                </div>
                <div style="width:10%" class="px-2">
                    <div class="border-b border-gray-500 pb-5px pl-5px">
                        <label class="block text-gray-700 fs-11px" style="height:20px;">
                            ${!!address.unitNumber ? address.unitNumber : ''}
                        </label>
                    </div>
                </div>
                <div style="width:18%" class="px-2">
                    <div class="border-b border-gray-500 pb-5px pl-5px">
                        <label class="block text-gray-700 fs-11px" style="height:20px;">
                            ${address.city}
                        </label>
                    </div>
                </div>
                <div style="width:18%" class="px-2">
                    <div class="border-b border-gray-500 pb-5px pl-5px">
                        <label class="block text-gray-700 fs-11px" style="height:20px;">
                            ${address.province}
                        </label>
                    </div>
                </div>
                <div style="width:10%" class="px-2">
                    <div class="border-b border-gray-500 pb-5px pl-5px">
                        <label class="block text-gray-700 fs-11px" style="height:20px;">
                            ${address.postalCode}
                        </label>
                    </div>
                </div>
                <div style="width:14%" class="px-2">
                    <div class="border-b border-gray-500 pb-5px pl-5px">
                        <label class="block text-gray-700 fs-11px" style="height:20px;">
                            ${address.country}
                        </label>
                    </div>
                </div>
            </div>
        `).join('');
        const additionalAddressHTML = `
            <div class="address-box previous-address">
                <div>
                    <p class="fs-11px px-1 flex items-center" style="margin:0px;">
                        Previous Address(es) Within the Last 5 Years (attach additional page if necessary)
                    </p>
                </div>
                ${otherAddresses}
            </div>
            `;

        const reasonForCRVHTML = `
        <div class="border border-gray-500 border-t-0 border-b-0">
                    <p class="fs-14px bg-gray-300 px-1 flex items-center" style="padding-bottom:15px;height:20px;font-weight:bold; margin:0px; line-height:16px;">
                        B. Reason for the Criminal Record Verification
                    </p>
                    <div class="flex flex-wrap border-b border-gray-500">
                        <div class="w-100 pb-5px pl-5px pr-5px">
                            <label class="block text-gray-700 fs-11px" >
                                Reason for Request (example Employment - Employer - Job Title): ${reasonForCRV.reasonForRequest}
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-wrap border-b border-gray-500">
                        <div class="w-100 pb-5px pl-5px pr-5px">
                            <label class="block text-gray-700 fs-11px" >
                                Organization Requesting Search: ${reasonForCRV.organizationRequestingSearch}
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-wrap border-b border-gray-500">
                        <div class="col-6 pb-5px pl-5px pr-5px">
                             <label class="block text-gray-700 fs-11px" >
                                  Contact Name: ${reasonForCRV.contactName}
                              </label>
                        </div>
                        <div class="col-6 border-l border-gray-500 pb-5px pl-5px pr-5px">
                             <label class="block text-gray-700 fs-11px" >
                                  Contact Phone Number: ${reasonForCRV.contactPhone}
                              </label>
                        </div>
                    </div>
            </div>
            `;

        const informedConsentHTML = `
        <div class="border border-gray-500 border-t-0 border-b-0">
                <p class="fs-14px bg-gray-300 px-1 flex items-center" style="padding-bottom:15px;height:20px;font-weight:bold; margin:0px; line-height:16px;">
                    C. Informed Consent
                </p>
                <div class="flex flex-wrap border-b border-gray-500">
                    <div class="w-100 pb-5px pl-5px pr-5px">
                        <label class="block text-gray-700 fs-11px pb-5px" >
                            <b>Search Authorization</b> – I HEREBY CONSENT TO THE SEARCH OF THE RCMP National Repository of Criminal Records based on the name(s), 
                            date of birth, and where used, the declared criminal record history provided by myself. I understand that this verification of the 
                            National Repository of Criminal Records is not being confirmed by fingerprint comparison which is the only true means by which to 
                            confirm if a criminal record exists in the National Repository of Criminal Records.
                        </label>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <div class="w-100 pb-5px pl-5px pr-5px">
                        <label class="block text-gray-700 fs-11px pb-5px" >
                            POLICE INFORMATION SYSTEM(S) – I HEREBY CONSENT TO THE SEARCH OF police information systems, as a part of a Police Information Check, 
                            which will consist of a search of the following systems (check applicable):
                        </label>
                    </div>
                </div>
                <div class="flex flex-wrap border-b border-gray-500">
                    <div class="col-4 pb-5px pl-5">
                        <div class="flex items-center">
                            <input type="checkbox" ${informedConsent.policeInfoSystems.CPICData ? 'checked' : ''} class="mr-2"/>
                            <label class="text-gray-700 fs-11px mb-2" >
                                 CPIC Investigative Data Bank
                            </label>
                        </div>
                    </div>
                    <div class="col-4 pb-5px pl-5">
                        <div class="flex items-center">
                            <input type="checkbox" ${informedConsent.policeInfoSystems.PIO ? 'checked' : ''} class="mr-2"/>
                            <label class="text-gray-700 fs-11px mb-2" >
                                 Police Information Portal (PIP)
                            </label>
                        </div>
                    </div>
                    <div class="col-4 pb-5px pl-5">
                        <div class="flex items-center">
                            <input type="checkbox" ${informedConsent.policeInfoSystems.other ? 'checked' : ''} class="mr-2"/>
                            <label class="text-gray-700 fs-11px mb-2" >
                                OTHER
                            </label>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <div class="w-100 pl-5px pr-5px">
                        <label class="block text-gray-700 fs-11px pb-5px" >
                            <b>AUTHORIZATION AND WAIVER</b> to provide confirmation of criminal record or any police information. 
                        </label>
                        <label class="block text-gray-700 fs-11px" >
                            I certify that the information set out by me in this application is true and correct to the best of my ability. 
                            I consent to the release of the results of the criminal record checks to
                        </label>
                    </div>
                </div>
                <div class="flex flex-wrap">
                        <div style="width:60%" class="pl-2">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    ${!!informedConsent.ThirdPartyOrg ? informedConsent.ThirdPartyOrg + " and" : ""} Metaforce Careers Inc.
                                 </label>
                            </div>
                            <div class="pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                    Company Name
                                 </label>
                            </div>
                        </div>
                        <div style="width:auto" class="px-2">
                            <label class="block text-gray-700 fs-11px mt-2">
                                , located in
                            </label>
                        </div>
                        <div style="width:25%">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    Mississauga, Canada
                                 </label>
                            </div>
                            <div class="pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                    City and Country
                                 </label>
                            </div>
                        </div>
                </div>
                <div class="flex flex-wrap">
                    <div class="w-100 pl-5px pr-5px">
                        <label class="block text-gray-700 fs-11px" >
                            I hereby release and forever discharge all members and employees of the processing Police Service and the Royal Canadian Mounted Police 
                            from any and all actions, claims, and demands for damages, loss, or injury howsoever arising which may hereafter be sustained by myself 
                            as a result of the disclosure of information by the 
                        </label>
                    </div>
                </div>
                <div class="flex" style="padding-bottom:20px;">
                        <div style="width:30%" class="pl-2">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    Cobourg Police Service
                                 </label>
                            </div>
                            <div class="pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                    Name of Processing Police Service
                                 </label>
                            </div>
                        </div>
                        <div style="width:auto" class="px-2">
                            <label class="block text-gray-700 fs-11px mt-2">
                                to
                            </label>
                        </div>
                        <div style="width:45%">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                   ${!!informedConsent.ThirdPartyOrg ? informedConsent.ThirdPartyOrg + " and" : ""} Metaforce Careers Inc.
                                 </label>
                            </div>
                            <div class="pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                    Company Name
                                 </label>
                            </div>
                        </div>
                        <div style="width:auto" class="px-2">
                            <label class="block text-gray-700 fs-11px mt-2">
                                ,
                            </label>
                        </div>
                        <div style="width:20%">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                    Mississauga, Canada
                                 </label>
                            </div>
                            <div class="pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                    City and Country
                                 </label>
                            </div>
                        </div>
                </div>
                <div class="flex flex-wrap border-b border-t border-gray-500">
                        <div class="col-4 pb-5px pl-5">
                            <label class="text-gray-700 fs-11px mb-2" >
                                 Signature of Applicant
                            </label>
                            <div class="" style="height:40px;">
                                <img src="${informedConsent.applicantSignature}" alt="signature" style="height:38px;max-width:100%;object-fit:contain;"/>
                            </div>
                        </div>
                        <div class="col-4 border-l border-gray-500 pb-5px pl-5px pr-5px">
                            <div class="flex items-center">
                                <label class="text-gray-700 fs-11px mb-2" >
                                     Date
                                </label>
                                <div>
                                    <label class="text-gray-700 fs-11px pl-5" >
                                        ${informedConsent.dateSigned}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 border-l border-gray-500 pb-5px pl-5px pr-5px">
                            <div class="flex items-center">
                                <label class="text-gray-700 fs-11px mb-2" >
                                    Signed At
                                </label>
                                 <div>
                                    <label class="text-gray-700 fs-11px pl-5" >
                                        ${informedConsent.SignedAt}
                                    </label>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            `;

        const IdentificationVerificationHTML = `
        <div class="border border-gray-500 border-t-0 border-b-0">
                    <div class="bg-gray-300 flex items-center flex items-center justify-between">
                        <p class="fs-14px px-1 mb-2" style="padding-bottom:15px;height:20px;font-weight:bold; line-height:16px;">
                            D. Identification Verification
                        </p>
                        <div class="flex items-center">
                            <input type="checkbox" class="mr-2"/>
                            <label class="text-gray-700 fs-11px mb-2 mr-2" >
                                 Electronic Identify Verification
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-wrap border-b border-gray-500">
                        <div class="col-6 pb-5px pl-5px pr-5px">
                             <label class="block text-gray-700 fs-11px" >
                                  Witnessing Agent's Name:
                              </label>
                        </div>
                        <div class="col-6 border-l border-gray-500 pb-5px pl-5px pr-5px">
                             <label class="block text-gray-700 fs-11px" >
                                  Identification Verified:
                              </label>
                        </div>
                    </div>
                    <div class="flex flex-wrap border-b border-gray-500">
                        <div class="col-6 pb-5px pl-5px pr-5px">
                             <label class="block text-gray-700 fs-11px" >
                                  Witnessing Agent's Signature
                              </label>
                        </div>
                        <div class="col-6 border-l border-gray-500 pb-5px pl-5px pr-5px">
                             <label class="block text-gray-700 fs-11px" >
                                  Type of Photo ID Viewed<br>
                                  <span style="font-size:10px !important;">(Government / Secondary)</span>
                              </label>
                        </div>
                    </div>
            </div>
            `;

        const docFooterHTML = `
            <div>
                <p class="fs-11px mt-1 mb-1" style="line-height:16px;">
                    Name and Location of the Company where information will be stored in Canada: <span style="line-height:16px;font-weight:bold;">Metaforce Careers Inc. Mississauga, Canada.</span>
                </p>
                <p class="fs-11px mb-1" style="line-height:16px;font-weight:bold;">
                    **lnformation related to this criminal record check is collected, retained and disclosed in accordance with applicable privacy legislation.**
                </p>
            </div>
        `;

        const CriminalRecord = `
                <p class="fs-13px mb-0 mt-0" style="font-weight:bold;line-height:16px;text-align:right; margin:0px;">
                         CRIMINAL RECORD VERIFICATION
                </p>
                <p class="fs-13px mt-0" style="font-weight:bold; line-height:16px;text-align:right;">
                         Declaration of Criminal Record Form
                </p>
                <p class="fs-11px mt-2 italic" style="line-height:16px;">
                    This form is required to be filled out and attached to your Informed Consent Form for a Criminal Record Verification.
                </p>
                <div class="flex flex-wrap" style="align-items:end;">
                    <div style="width:auto;">
                        <label class="block text-gray-700 fs-11px" style="line-height:20px;">
                            Surname (last name)
                        </label>
                    </div>
                    <div style="width:25%" class="px-1">
                        <div class="border-b border-gray-500 pb-5px pl-5px">
                            <label class="block text-gray-700 fs-11px">
                              ${criminalRecord.lastName}
                            </label>
                        </div>
                    </div>
                    <div style="width:auto;">
                        <label class="block text-gray-700 fs-11px" style="line-height:20px;">
                            Given name(s)
                        </label>
                    </div>
                    <div style="width:25%;" class="px-1">
                        <div class="border-b border-gray-500 pb-5px pl-5px">
                            <label class="block text-gray-700 fs-11px">
                                ${criminalRecord.givenName}
                            </label>
                        </div>
                    </div>
                    <div style="width:auto;">
                        <label class="block text-gray-700 fs-11px" style="line-height:20px;">
                            Date of Birth
                        </label>
                    </div>
                    <div style="width:15%;" class="px-1">
                        <div class="border-b border-gray-500 pb-5px pl-5px">
                            <label class="block text-gray-700 fs-11px">
                                ${criminalRecord.dob}
                            </label>
                        </div>
                    </div>
                </div>
                <p class="fs-11px" style="line-height:16px;">
                    Information is collected and disclosed in accordance with federal, provincial and municipal laws.
                </p>
                <p class="fs-11px" style="line-height:16px;">
                    A Declaration of Criminal Record does not constitute a Certified Criminal Record by the RCMP and may not contain all criminal
                    record convictions.
                </p>
                <p class="fs-11px" style="line-height:16px;">
                    Applicants must declare all convictions for offences under Canadian federal law.
                </p>
                <div>
                    <p class="fs-11px mb-0 mt-0" style="line-height:16px;">
                        <span class="border-b border-gray-500 pb-5px">Do not declare the following:</span>
                    </p>
                    <p class="fs-11px mb-0 mt-0" style="line-height:16px;">
                        -A conviction for which you have received a Record Suspension (formerly pardon) in accordance with the Criminal Records Act;
                    </p>
                    <p class="fs-11px mb-0 mt-0" style="line-height:16px;">
                        -A conviction where you were a “young person” under the Youth Criminal Justice Act;
                    </p>
                    <p class="fs-11px mb-0 mt-0" style="line-height:16px;">
                        -An Absolute or Conditional Discharge, pursuant to section 730 of the Criminal Code;
                    </p>
                    <p class="fs-11px mb-0 mt-0" style="line-height:16px;">
                        -An offence for which you were not convicted;
                    </p>
                    <p class="fs-11px mb-0 mt-0" style="line-height:16px;">
                        -Any provincial or municipal offence, and;
                    </p>
                    <p class="fs-11px mb-0 mt-0" style="line-height:25px;">
                        -Any charged dealt with outside of Canada.
                    </p>
                </div>
            `;

        const paddedOffenceDetails = padOffenceDetails(criminalRecord.offenceDetails);

        const OffenceContent = paddedOffenceDetails.slice(0, 6).map((offence, index) => `
            <div class="flex flex-wrap border-b border-gray-500">
                <div class="pb-5px pl-5px pr-5px" style="width:45%;">
                     <label class="block text-gray-700 fs-11px text-center mb-1" style="height:20px;" >
                          ${!!offence.offence ? offence.offence : ''}
                      </label>
                </div>
                <div class="border-l border-gray-500 pb-5px pl-5px pr-5px" style="width:20%;">
                     <label class="block text-gray-700 fs-11px text-center mb-1" style="height:20px;">
                        ${!!offence.dos ? offence.dos : ''}
                      </label>
                </div>
                <div class="border-l border-gray-500 pb-5px pl-5px pr-5px" style="width:35%;">
                     <label class="block text-gray-700 fs-11px text-center mb-1" style="height:20px;">
                        ${!!offence.courtLocation ? offence.courtLocation : ''}
                      </label>
                </div>
            </div>
            `).join('');
        const OffenceDetailsHTML = `
                <div>
                    <label class="block text-gray-700 fs-11px pb-5px">
                        <b>Note that a Certified Criminal Record can only be issued based on the submission of fingerprints to the RCMP National
                        Repository of Criminal Record.</b>
                    </label>
                </div>
                <div class="border border-gray-500 border-b-0">
                   
                    <div class="flex flex-wrap border-b border-gray-500">
                        <div class="pb-5px pl-5px pr-5px bg-gray-300" style="width:45%;">
                             <label class="block text-black fs-11px font-bold text-center mb-1" style="height:20px;" >
                                  Offence
                              </label>
                        </div>
                        <div class="border-l border-gray-500 pb-5px pl-5px pr-5px bg-gray-300" style="width:20%;">
                             <label class="block text-black fs-11px font-bold text-center mb-1" style="height:20px;">
                                  Date of Sentence
                              </label>
                        </div>
                        <div class="border-l border-gray-500 pb-5px pl-5px pr-5px bg-gray-300" style="width:35%;">
                             <label class="block text-black fs-11px font-bold text-center mb-1" style="height:20px;">
                                  Court Location
                              </label>
                        </div>
                    </div>
                    ${OffenceContent}
                </div>
            `;


        element.innerHTML = `
            <style>
                    .fs-10px {
                        font-size: 10px;
                    }
                    .fs-11px {
                        font-size: 11px;
                    }
                    .fs-12px {
                        font-size: 12px;
                    }
                    .fs-13px {
                        font-size: 13px;
                    }
                    .fs-14px {
                        font-size: 14px;
                    }
                    .fs-15px {
                        font-size: 15px;
                    }
                    .fs-16px {
                        font-size: 16px;
                    }
                    .fs-17px {
                        font-size: 17px;
                    }
                    .fs-18px {
                        font-size: 18px;
                    }
                    .fs-19px {
                        font-size: 19px;
                    }
                    .fs-20px {
                        font-size: 20px;
                    }
                    .flex {
                        display: flex;
                    }
                    .justify-start {
                        justify-content: flex-start;
                    }
                    .justify-center {
                        justify-content: center;
                    }
                    .justify-end {
                        justify-content: flex-end;
                    }
                    .justify-between {
                        justify-content: space-between;
                    }
                    .items-start {
                        align-items: flex-start;
                    }
                    .items-center {
                        align-items: center;
                    }
                    .items-end {
                        align-items: flex-end;
                    }
                    .col-6{
                        width:50%;
                    }
                    .col-4{
                        width:33.33%;
                    }
                    .col-3{
                        width:25%;
                    }
                    .flex-wrap {
                        flex-wrap: wrap;
                    }
                    .pb-5px{
                        padding-bottom:5px;
                    }
                    .pl-5px{
                        padding-left:5px;
                    }
                    .w-100{
                      width:100%;
                    }
                    .pr-5px{
                        padding-right:5px;
                    }
                    .page-breake-after{
                        page-break-after: always !important;
                    }  
                    
                    @media print {
                        .fs-10px {
                        font-size: 10px;
                        }
                        .fs-11px {
                            font-size: 11px;
                        }
                        .fs-12px {
                            font-size: 12px;
                        }
                        .fs-13px {
                            font-size: 13px;
                        }
                        .fs-14px {
                            font-size: 14px;
                        }
                        .fs-15px {
                            font-size: 15px;
                        }
                        .fs-16px {
                            font-size: 16px;
                        }
                        .fs-17px {
                            font-size: 17px;
                        }
                        .fs-18px {
                            font-size: 18px;
                        }
                        .fs-19px {
                            font-size: 19px;
                        }
                        .fs-20px {
                            font-size: 20px;
                        }
                        .flex {
                            display: flex;
                        }
                        .justify-start {
                            justify-content: flex-start;
                        }
                        .justify-center {
                            justify-content: center;
                        }
                        .justify-end {
                            justify-content: flex-end;
                        }
                        .justify-between {
                            justify-content: space-between;
                        }
                        .items-start {
                            align-items: flex-start;
                        }
                        .items-center {
                            align-items: center;
                        }
                        .items-end {
                            align-items: flex-end;
                        }
                        .col-6{
                            width:50%;
                        }
                        .col-4{
                            width:33.33%;
                        }
                        .col-3{
                            width:25%;
                        }
                        .flex-wrap {
                            flex-wrap: wrap;
                        }
                        .pb-5px{
                            padding-bottom:5px;
                        }
                        .pl-5px{
                            padding-left:5px;
                        }
                        .w-100{
                          width:100%;
                        }
                        .pr-5px{
                            padding-right:5px;
                        }
                        .page-breake-after{
                            page-break-after: always !important;
                        }
                        .flex-wrap{
                            flex-wrap: wrap !important;
                        }
                    }
            </style>
            <div id="first-page" class="page-breake-after">
                ${docHeaderHTML}
                <div class="border border-gray-500 border-b-0">
                    ${personalInfoHTML}
                    ${currentAddressHTML}
                    ${previousAddressHTML}
                </div>
                ${reasonForCRVHTML}
                ${informedConsentHTML}
                ${IdentificationVerificationHTML}
                ${docFooterHTML}   
            </div>
            <div id="second-page" class="${previousAddress.length > 2 ? 'page-breake-after' : ''}">
                ${CriminalRecord}
                ${OffenceDetailsHTML}
                <div class="flex flex-wrap mt-5">
                        <div style="width:45%">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <div class="" style="height:40px;">
                                    <img src="${informedConsent.applicantSignature}" alt="signature" style="height:38px;max-width:100%;object-fit:contain;"/>
                                </div>
                            </div>
                            <div class="pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                    Signature of Applicant
                                 </label>
                            </div>
                        </div>
                        <div style="width:5%;" class="px-2">
                            <label class="block text-gray-700 fs-11px mt-2">
                            </label>
                        </div>
                        <div style="width:20%">
                            <div class="border-b border-gray-500 pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px d-flex items-end justify-center" style="height:40px;">
                                ${informedConsent.dateSigned}
                                 </label>
                            </div>
                            <div class="pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                    Date (YYYY-MM-DD)
                                 </label>
                            </div>
                        </div>
                </div>
                <div class="mt-4">
                    <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                        Verified By:
                    </label>
                </div>
                <div class="mt-2">
                    <div style="width:35%">
                            <div  style="border-bottom: 1px solid #6B7280; padding-bottom: 5px; padding-left: 5px;">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                 </label>
                            </div>
                            <div class="pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                    Name of Police Agency Employee
                                 </label>
                            </div>
                        </div>
                </div>
                <div class="mt-2">
                    <div style="width:35%">
                            <div style="border-bottom: 1px solid #6B7280; padding-bottom: 5px; padding-left: 5px;">
                                <label class="block text-gray-700 fs-11px" style="height:20px;">
                                 </label>
                            </div>
                            <div class="pb-5px pl-5px">
                                <label class="block text-gray-700 fs-11px" style="margin-top:-5px;">
                                    Signature of Police Agency Employee
                                 </label>
                            </div>
                        </div>
                </div>
            </div>
            ${previousAddress.length > 2 ? `
                <div id="third-page">
                <div class="border border-gray-500 border-b-0">
                    <p class="fs-14px bg-gray-300 px-1 flex items-center" style="padding-bottom:15px;height:20px;font-weight:bold; margin:0px; line-height:16px;">
                        E.Additional Address
                    </p>
                    ${additionalAddressHTML}
                </div>
            </div>
                `: ""
            }

            `;

        const options = {
            filename: 'form-data.pdf',
            margin: [20, 20, 20, 20],
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: "css" },
            html2canvas: { scale: 2 }
        };
        try {
            const pdfBlob = await html2pdf().from(element).set(options).outputPdf('blob');
            const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());

            const form = pdfDoc.getForm();
            const page1 = pdfDoc.getPage(0);
            const page2 = pdfDoc.getPage(1);

            const transparentColor = rgb(1, 1, 1, 0);

            function createTransparentTextField(form, page, fieldName, x, y, width, height, multiline = false) {
                const textField = form.createTextField(fieldName);
                textField.setText('');
                textField.addToPage(page, {
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                    borderColor: transparentColor,
                    backgroundColor: transparentColor,
                });
                textField.setFontSize(9);
                if (multiline) {
                    textField.enableMultiline();
                }
                return textField;
            }
            createTransparentTextField(form, page1, 'EditableField1', 132, 109, 165, 15, false);
            createTransparentTextField(form, page1, 'EditableField2', 391, 109, 183, 15, false);
            createTransparentTextField(form, page1, 'EditableField3', 143, 88, 154, 15, false);
            createTransparentTextField(form, page1, 'EditableField4', 400, 80, 173, 22, true);
            // createTransparentTextField(form, page2, 'EditableField5', 20, 304, 193, 15, false);
            // createTransparentTextField(form, page2, 'EditableField6', 20, 266, 193, 15, false);


            const pdfBytes = await pdfDoc.save();
            const editablePDFBlob = new Blob([pdfBytes], { type: 'application/pdf' });

            // const link = document.createElement('a');
            // link.href = URL.createObjectURL(editablePDFBlob);
            // link.download = 'editable-document.pdf';
            // link.click();

            // html2pdf().from(element).set(options).save();

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                onPdfGenerated(base64String);
            };
            reader.readAsDataURL(editablePDFBlob);
        } catch (error) {
            console.error('Error generating PDF:', error);
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        if (triggerPdf) {
            generatePdf();
        }
    }, [triggerPdf]);

    // return (
    //     <div>
    //         <Button onClick={generatePdf}>Generate PDF</Button>
    //     </div>
    // );
    return null;
};

export default PdfGenerator;
