/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import whyImage from '../../OIFendImages/images/Home Page/Banner/svg/why oifend.svg';
import rocketImage from '../../OIFendImages/images/Home Page/Icon/svg/rocket.svg';
import productivityImage from '../../OIFendImages/images/Home Page/Icon/svg/productivity.svg';
import pricingImage from '../../OIFendImages/images/Home Page/Icon/svg/pricing.svg';
import backgrouncheck from '../../OIFendImages/images/Home Page/Services/svg/background-check-home.svg';


// Services images
import customer from '../../Images/customer.svg';
import lumber from '../../Images/lumber.svg';
import distribution from '../../Images/distribution.svg';
import truck from '../../Images/truck.svg';
import general from '../../Images/general.svg';
import adminstration from '../../Images/adminstration.svg';

import './index.less';
import '../../Components/button.less';

import { Link } from 'react-router-dom';
import ImagesGallery from '../ImagesGallery/ImagesGallery';


const index = () => {

  return (
    <div className="">
      <div className="bgMainpage bg-no-repeat bg-fixed "
      >
        <div >
          <div className="z-50" >
            <div className="w-full flex justify-center " >
              <div className="pt-0 md:pt-10 mainBannerContent">
                <div className="mt-0 pt-10 lg:pt-0 font-bold text-4xl text-white ">
                  MetaForce Careers
                </div>
                <div className="mt-4 container flex justify-center mb-8 md:mb-0 text-lg leading-relaxed text-white text-center md:text-left ">
                  <span className="mt-2 mb-0 md:mb-6 flex ml-0 md:pl-4 p-4  shadow-md text-center md:text-left px-4 text-xl">Helping you attract and retain the right talent.</span>
                </div>
                {/* <span style={{width:'150px'}} className="mt-8 md:mt-4 uppercase rounded-full px-5 py-3 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden">
                    <Link to="/services" >
                      View Our Services
                    </Link>
                  </span> */}

              </div>
            </div>
          </div>

        </div>
      </div>

      {/* Home page About */}
      <div className="container mx-auto text-center mb-2 mt-12">

        <h3 className="text-lg lg:text-2xl font-normal lg:font-normal sm:text-xl leading-normal font-normal tracking-tight text-gray-900">
          Welcome to METAFORCE CAREERS! MetaForce is a full-service employment agency focusing specifically on General Labour, lumpers sourcing, recruiting, screening and placing top candidates in the Manufacturing and Logistics Industries across Canada.
        </h3>
      </div>
      {/* End about */}

      {/* Why Meta force */}
      <div className="flex  mt-10 flex-col md:flex-row mb-8">
        <div className="whyImage">
          <img alt="" className=" rounded-lg mt-10" src={whyImage} />
        </div>
        <div className="w-full md:w-6/12 pl-4 md:pl-8 pr-0 md:pr-16">
          <div className="pt-0 md:pt-10 mt-0 md:mt-12">
            <div className=" text-center md:text-left my-4 md:my-0">
              <h2 className="font-semibold text-2xl mb-4"><span className="border-b-2 border-blue-600 pb-1">Why MetaForce?</span></h2>
              <h4 className="mt-0 pt-0 text-sm lg:text-lg">Learn all the reason why you should use MetaForce</h4>
            </div>

            <ul className="w-full rounded-lg mt-2 mb-3 text-blue-800">
              <li className="mb-1">
                <div href="#" className="w-fill flex p-3 pl-3  shadow rounded-lg">
                  <img alt="" className="rounded-lg" style={{ height: '60px' }} src={rocketImage} />
                  <span className="ml-2 truncate text-lg mt-4 font-bold  text-black">Speed Service</span>
                </div>
              </li>
              <li className="mb-1">
                <div href="#" className="w-fill flex p-3  shadow rounded-lg">
                  <img alt="" className="rounded-lg" style={{ height: '60px' }} src={productivityImage} />
                  <span className="ml-2 truncate text-lg mt-4 font-bold  text-black">Increased Productivity</span>
                </div>
              </li>
              <li>
                <div href="#" className="w-fill flex p-3  shadow rounded-lg">
                  <img alt="" className="rounded-lg" style={{ height: '60px' }} src={pricingImage} />
                  <span className="ml-2 truncate text-lg mt-4 font-bold text-black">Ultimate Pricing</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Why Meta Force */}

      {/* Gallery */}
      <div className='flex justify-center text-center mb-4 text-4xl font-bold'>
        <span className='border-b-2 primaryColor border-indigo-400'>
          Gallery
        </span>
      </div>
      <ImagesGallery />
      {/* END here */}

      {/* component */}
      <div className="pt-12 pb-8">
        <div>
          <div className='flex justify-center primaryColor text-center mb-8 text-4xl font-bold'>
            <span className='border-b-2 border-indigo-400'>
              Services
            </span>
          </div>
        </div>
        <div className="container m-auto px-6 mb-4 text-gray-500 md:px-12 xl:px-0">
          <div className="mx-auto grid gap-6 md:w-1/2 lg:w-full lg:grid-cols-2">
            <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
              <div className="mb-12 space-y-4">
                <h3 className="text-2xl font-semibold text-purple-900">General Labour</h3>
                <p className="mb-6 text-left text-md">General Laborers are in charge of digging trenches, unloading building materials, and preparing job sites. While operating machines, they employ hand tools such as shovels or picks to aid them in their work and support crews with other important chores.</p>
              </div>
              <img src={general} className="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width={900} height={600} />
            </div>
            <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
              <div className="mb-12 space-y-4">
                <h3 className="text-2xl font-semibold text-purple-900">Background Check</h3>
                <p className="mb-6 text-left text-md">Get the information you need to make informed decisions quickly and confidentially. Trust our accurate, thorough background checks for individuals and businesses.</p>
              </div>
              <img src={backgrouncheck} className="w-2/4 ml-auto -mb-12" alt="illustration" loading="lazy" width={900} height={600} />
            </div>
          </div>
        </div>
        <div className="container m-auto px-6 mb-4 text-gray-500 md:px-12 xl:px-0">
          <div className="mx-auto grid gap-6 md:w-1/2 lg:w-full lg:grid-cols-3">
            <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
              <div className="mb-12 space-y-4">
                <h3 className="text-2xl font-semibold text-purple-900">Lumper services</h3>
                <p className="mb-6 text-left text-md">A lumper is a person who works in the supply chain loading or unloading cargo goods. Lumpers are most typically employed in the food and grocery industries, although they can also help with supply chain distribution in other industries.</p>
              </div>
              <img src={lumber} className="w-2/3 ml-auto" alt="illustration" loading="lazy" width={900} height={600} />
            </div>
            <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
              <div className="mb-12 space-y-4">
                <h3 className="text-2xl font-semibold text-purple-900">Administration & Operations</h3>
                <p className="mb-6 text-left text-md">As experts in assessing candidates for appropriate jobs, MetaForce solutions is able to focus on your business and deliver quality results several innovative strategies</p>
              </div>
              <img src={adminstration} className="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width={900} height={600} />
            </div>
            <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
              <div className="mb-12 space-y-4">
                <h3 className="text-2xl font-semibold text-purple-900">Customer Service</h3>
                <p className="mb-6 text-left text-md">MetaForce Solutions currently works with some of the most respected call centres in Ontario. With an endless effort to secure excellent employers call centre placement in Ontario</p>
              </div>
              <img src={customer} className="w-2/3 ml-auto" alt="illustration" loading="lazy" width={900} height={600} />
            </div>
          </div>
        </div>
        <div className="container m-auto px-6 text-gray-500 md:px-12 xl:px-0">
          <div className="mx-auto grid gap-6 md:w-1/2 lg:w-full lg:grid-cols-2">
            <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
              <div className="mb-12 space-y-4">
                <h3 className="text-2xl font-semibold text-purple-900">Distribution - Warehouse/Manufacturing</h3>
                <p className="mb-6 text-left text-md">Whether you’re looking for permanent or temporary warehouse and manufacturing workers, MetaForce, expertise and local market knowledge will help you find the best people.</p>
              </div>
              <img src={distribution} className="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width={900} height={600} />
            </div>
            <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
              <div className="mb-12 space-y-4">
                <h3 className="text-2xl font-semibold text-purple-900">Truck Drivers</h3>
                <p className="mb-6 text-left text-md">We also offer lucrative Driver jobs responsible for making deliveries in different stores across Ontario.Responsibilities also includes delivering parts to customers and for picking up parts from suppliers for the dealership.</p>
              </div>
              <img src={truck} className="w-2/3 ml-auto" alt="illustration" loading="lazy" width={900} height={600} />
            </div>
          </div>
        </div>
      </div>
      <div className='py-8'>
        <span style={{ width: '150px' }} className="mt-8 md:mt-4 bgPrimaryColor uppercase rounded-full px-5 py-3 mb-16 text-center md:text-left bg-white hover:bg-blue-800 transform transition duration-500 hover:text-white relative focus:outline-none border-2 border-solid rounded-lg text-sm font-semibold tracking-widest overflow-hidden">
          <Link to="/services" style={{ color: "white" }} >
            View Our Services
          </Link>
        </span>
      </div>
    </div>
  )
}

export default index
